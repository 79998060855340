import {defineStore} from 'pinia';
import backendCall from "../services/AxiosService";


export const useTrainsStore = defineStore({
    id: 'trains', state: () => ({
        lineup: [],
        trainCount: null
    }), actions: {
         fetchTrains() {
            backendCall.get('trains/lineup')
                .then(response => {
                    const data = response.data;
                    if (Array.isArray(data.content)) {
                        const newTrains = data.content.map(train => ({
                            ...train,
                            origin_depart_date_string: new Date(train.train_origin_departure_date).toDateString(),
                            eta_string: train.eta ? new Date(train.eta).toLocaleString() : null,
                            location: '',
                        }));

                        this.lineup = newTrains;
                        this.trainCount = newTrains.length;



                    }
                })
                .catch(error => {
                    console.error('There was a problem with the axios operation:', error);
                });
        },
      async  submitWorkerStartTime (trainId: number) {
                try {
                    const data = {type: 'train', train: trainId}
                    await backendCall.post('time/start', data)
                    return true;
                } catch (error) {
                    console.error('Error submitting train arrival:', error);
                    throw error;
                }
            },
        async  submitWorkerEndTime (trainId: number) {
            try {
                const data = {type: 'train', train: trainId}
                await backendCall.post('time/end', data)
                return true;
            } catch (error) {
                console.error('Error submitting train arrival:', error);
                throw error;
            }
        },
        async submitTrainArrival(trainId: number) {
            try {
                await backendCall.post(`trains/${trainId}/arrival`);
                return true;
            } catch (error) {
                console.error('Error submitting train arrival:', error);
                throw error;
            }
        },
        //todo build api
        async submitBeginWaitTime(trainId: number) {
            try {
                // await backendCall.post(`trains/${trainId}/arrival`);
                return true;
            } catch (error) {
                console.error('Error submitting train arrival:', error);
                throw error;
            }
        },
        //todo build api
        async submitEndWaitTime(trainId: string) {
            try {
                await backendCall.post(`trains/${trainId}/arrival`);
                return true;
            } catch (error) {
                console.error('Error submitting train arrival:', error);
                throw error;
            }
        },
        async submitBeginTrackProtection(track: number, trainId: number) {
            try {
                //todo build api

                // await backendCall.post(`trains/${trainId}/arrival`);
                return true;
            } catch (error) {
                console.error('Error submitting train arrival:', error);
                throw error;
            }
        },
        async submitEndTrackProtection(track: number ,trainId: number) {
             //todo build api
            try {
                await backendCall.post(`trains/${trainId}/arrival`);
                return true;
            } catch (error) {
                console.error('Error submitting train arrival:', error);
                throw error;
            }
        },
        async submitTrainComplete(trainId: number) {
            try {
                await backendCall.post(`trains/${trainId}/arrival`);
                return true;
            } catch (error) {
                console.error('Error submitting train arrival:', error);
                throw error;
            }
        },
        }
});
