<script setup lang="ts">
import {computed, onMounted, ref, watch} from "vue";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import backendCall from "../../services/AxiosService.js";
import {useReferenceStore} from "../../stores/referenceStore";
import {useGeolocation} from "@vueuse/core";
import {useToast} from "primevue/usetoast";

const emit = defineEmits(['submitted']);

const referenceStore = useReferenceStore();

const toast = useToast();

const {coords, locatedAt, error, resume, pause} = useGeolocation()

onMounted(async () => {
  await fetchAlarmList()
  if (!allTracks.value) {
    await referenceStore.fetchReferenceData()
  }
})

const alarmSuggestions = ref<string[]>([]);
const allAlarms = ref<string[]>([]);

// Modify fetchAlarmList to update alarmSuggestions
const fetchAlarmList = async () => {
  try {

    allAlarms.value = [...referenceStore.alarms];
    // Update alarmSuggestions here
    alarmSuggestions.value = [...allAlarms.value];
    // console.log(allAlarms.value);
  } catch (error) {
    console.error("Error fetching alarm list:", error);
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Failed to fetch alarm list',
      life: 3000
    });
  }
}

const searchAlarms = (event: { query: string }) => {
  const query = event.query.trim().toLowerCase();

  if (query.length === 0) {
    alarmSuggestions.value = [...allAlarms.value];
  } else {
    alarmSuggestions.value = allAlarms.value.filter((alarm) =>
        alarm.alarm_code.toLowerCase().includes(query) || alarm.alarm_name.toLowerCase().includes(query)
    );
  }
};


const trackSuggestions = ref<string[]>([]);
const allTracks = ref();


const searchTracks = (event: { query: string }) => {
  const query = event.query.trim().toUpperCase();

  if (query.length === 0) {
    trackSuggestions.value = [...allTracks.value];
  } else {
    trackSuggestions.value = allTracks.value.filter((track) =>
        track.track_id.toUpperCase().startsWith(query) || track.track_name.toUpperCase().startsWith(query)
    );
  }
};

watch(() => referenceStore.tracks, (newValue) => {
  allTracks.value = newValue;
});


const visible = defineModel('visible');
const props = defineProps({visible: Boolean})
const refuel = ref({
  unitNumber: '',
  attachedElectricUnit: null,
  track: null,
  truckNumber: null,
  unitType: undefined,
  equipmentType: undefined,
  setpoint: {value: null, unit: null, rawInput: null},
  returnTemp: {value: null, unit: null, rawInput: null},
  supplyTemp: {value: null, unit: null, rawInput: null},
  opMode: null,
  inDefrost: false,
  fuelAdded: null,
  startFuelLevel: '',
  endFuelLevel: '',
  notes: '',
  browserCoordinates: null,
  alarms: null,
})

watch(coords, (newCoords) => {
  if (newCoords.latitude && newCoords.longitude && newCoords.accuracy) {
    refuel.value.browserCoordinates = {
      latitude: newCoords.latitude,
      longitude: newCoords.longitude,
      accuracy: newCoords.accuracy,
    };
  }
});

function validateTemperatureField(field: keyof refuel): boolean {
  const data = refuel.value[field] as TemperatureData;
  if (!data.rawInput) {
    invalidFields.value.delete(field);
    return true; // Consider empty field as valid
  }

  const match = data.rawInput.match(/^(-?\d+(\.\d+)?)(C|F)$/i);
  if (match) {
    const [, numPart, , unitPart] = match;
    const numValue = parseFloat(numPart);
    const unitValue = unitPart.toUpperCase() as 'C' | 'F';

    refuel.value[field] = {
      value: numValue,
      unit: unitValue,
      rawInput: data.rawInput
    };

    invalidFields.value.delete(field);
    // checkTemperatureDiscrepancies();
    return true;
  }

  invalidFields.value.add(field);
  toast.add({
    severity: 'error',
    summary: 'Invalid Temperature',
    detail: `${field} must be a number followed by C or F`,
    life: 5000
  });
  return false;
}

const unitNumberValidation = ref();
const attachedElectricNumberValidation = ref();


const invalidFields = ref(new Set<string>());

const fuelOptions = ref(['E', '1/8', '1/4', '3/8', '5/16', '7/16', '1/2', '9/16', '5/8', '3/4', '13/16', '7/8', 'F'])

const unitTypeOptions = ref(['KR1', 'KR2', 'KR4', 'KR7', 'KH7', 'KG4']);

const fuelTruckOptions = ref([6, 9, 11, 12, 14, 15, 16]);

const isCNPowerpack = computed(() => /^CNGU1[89]\d{2}$/.test(refuel.value.unitNumber));
const isContainer = computed(() => /^[A-Za-z]{3}U\d+$/.test(refuel.value.unitNumber) && !isCNPowerpack.value);
const isClipOn = computed(() => /^[A-Za-z]{3}J\d+$/.test(refuel.value.unitNumber));
const isChassis = computed(() => /^[A-Za-z]{3}Z\d+$/.test(refuel.value.unitNumber));


const equipmentTypeOptions = ref([{name: "CONTAINER", value: 1},
  {name: "POWERPACK", value: 2},
  {name: "CLIP-ON", value: 3},
  {name: "UNDERMOUNT", value: 4},
  {name: "JER VEHICLE", value: 6},
  {name: "EXTERNAL VEHICLE", value: 7},
  {name: "MISC EQUIPMENT", value: 9}]);

async function getUserVehicle() {
  const getUserVehicleCall = await backendCall.get("/user/vehicle-assignment");
  refuel.value.truckNumber = getUserVehicleCall?.data?.data?.vehicleAssignment;
}

async function validateUnitNumber(): Promise<void> {
  getUserVehicle().catch((err) => {
    console.error(err)
  })
  // isClipOn = /^[A-Za-z]{3}J\d+$/.test(refuel.value.unitNumber);
  if (!refuel.value.unitNumber) {
    invalidFields.value.add('unitNumber');
    return;
  }
  // Format the unit number before validation
  const unitNumber = refuel.value.unitNumber;

  try {
    const response = await backendCall.get(`equipment/lookup?unit_number=${unitNumber}`);
    // console.log(response?.data?.data?.cnApiResponse?.CarKind);
    unitNumberValidation.value = response?.data?.data;
    refuel.value.unitType = null;

    if (unitNumberValidation.value?.isContainer) {
      refuel.value.equipmentType = 1;
    } else if (unitNumberValidation.value?.isPowerpack) {
      refuel.value.equipmentType = 2;
    } else if (unitNumberValidation.value?.isUndermountGenset) {
      refuel.value.equipmentType = 4;
    } else if (unitNumberValidation.value?.isInternalFleet) {
      refuel.value.equipmentType = 6;
    } else {
      refuel.value.equipmentType = null;
    }

    if (isClipOn.value) {
      refuel.value.equipmentType = 3;
    }

    if (response?.data?.data?.dbResponse?.unit_type) {
      refuel.value.unitType = response?.data?.data?.dbResponse?.unit_type;
    } else if (response?.data?.data?.cnApiResponse?.CarKind) {
      refuel.value.unitType = response?.data?.data?.cnApiResponse.CarKind;
    }
    if (response.data.data.isValid) {
      invalidFields.value.delete('unitNumber');
    } else {
      invalidFields.value.add('unitNumber');
      // Uncomment to add toast warning as well.
      // toast.add({
      //   severity: 'warn',
      //   summary: 'Possible Invalid Unit Number!',
      //   detail: 'The entered unit number may not be valid! Please verify the unit number.',
      //   life: 10000
      // });
    }
    if (refuel.value?.unitType === 'KR1' || refuel.value?.unitType === 'KR2' || refuel.value?.unitType === 'KR4') {
      invalidFields.value.add('unitNumber');
    }
  } catch (error) {
    console.error('Error validating unit number:', error);
    toast.add({
      severity: 'error',
      summary: 'Validation Error',
      detail: 'An error occurred while validating the unit number.',
      life: 3000
    });
    invalidFields.value.add('unitNumber');
  }
}

async function validateAttachedElectricNumber(): Promise<void> {
  const isContainer = computed(() => /^[A-Za-z]{3}U\d{6}$/.test(refuel.value.attachedElectricUnit));
  const isElectricUnitNumber = ref(false);
  if (!refuel.value.attachedElectricUnit && !refuel.value.setpoint && !refuel.value.returnTemp && !refuel.value.supplyTemp && !refuel.value.inDefrost) {
    invalidFields.value.delete('attachedElectricUnit');
    return;
  }
  // Format the unit number before validation
  const unitNumber = refuel.value.attachedElectricUnit;
  let attachedElectricType: string | null;
  try {
    if (isContainer) {
      const response = await backendCall.get(`equipment/lookup?unit_number=${unitNumber}`);
      // console.log(response?.data?.data?.cnApiResponse?.CarKind);
      attachedElectricNumberValidation.value = response?.data?.data;


      if (response?.data?.data?.dbResponse?.unit_type) {
        attachedElectricType = response?.data?.data?.dbResponse?.unit_type;
      } else if (response?.data?.data?.cnApiResponse?.CarKind) {
        attachedElectricType = response?.data?.data?.cnApiResponse.CarKind;
      }

      if (attachedElectricType === "KR1" || attachedElectricType === "KR2" || attachedElectricType === "KR4") {
        isElectricUnitNumber.value = true;
      } else isElectricUnitNumber.value = response?.data?.data?.dbResponse?.elec_diesel === "ELECTRIC";

      if (isElectricUnitNumber.value) {
        invalidFields.value.delete('attachedElectricUnit');
      } else {
        invalidFields.value.add('attachedElectricUnit');
      }

    }
  } catch (error) {
    console.error('Error validating unit number:', error);
    toast.add({
      severity: 'error',
      summary: 'Validation Error',
      detail: 'An error occurred while validating the unit number.',
      life: 3000
    });
    invalidFields.value.add('unitNumber');
  }
}


const isSubmitting = ref(false);

async function submitRefuel() {
  try {
    const temperatureFields = ['setpoint', 'returnTemp', 'supplyTemp'] as const;
    for (const field of temperatureFields) {
      if (!validateTemperatureField(field)) {
        isSubmitting.value = false;
        return;
      }
    }
    isSubmitting.value = true;
    const response = await backendCall.post('fuel', refuel.value);
    if (response.status >= 400) {
      throw new Error('Failed to submit refuel');
    } else {
      console.log(response);
      emit('submitted');
    }
  } catch (error) {
    // console.log(e.response);
    toast.add({
      severity: 'error',
      summary: 'Failed to submit unit info!',
      detail: ``,
      life: 3000
    });
    console.error(error);
  } finally {
    isSubmitting.value = false;
  }
}


</script>

<template>
  <!--v-model:visible="visible"-->
  <Dialog v-model:visible="visible" appendTo="body" modal :breakpoints="{'960px': '75vw', '640px': '100vw'}"
          :style="{width: '35vw'}" header="New Refuel" :draggable="false" :resizable="false">
    <!--    <template #header>-->
    <!--      <div class="flex flex-col gap-2">-->
    <!--        <h1 class="m-0 text-surface-900 dark:text-surface-0 font-semibold text-xl leading-normal">New Refuel</h1>-->
    <!--      </div>-->
    <!--    </template>-->
    <form class="flex flex-col gap-4 mt-4">
      <div class="flex gap-4">
        <div class="w-full">
          <label for="unit-number" class="block mb-1 text-color text-base">Refueled Equipment Number</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-hashtag mb-2"></i>
            </InputGroupAddon>
            <InputText name="unit-number" v-model.trim="refuel.unitNumber" :invalid="invalidFields.has('unitNumber')"
                       @blur="validateUnitNumber" type="text" class="w-full" id="unit-number"/>
          </InputGroup>
          <inline-message v-if="refuel?.unitType === 'KR1' || refuel?.unitType === 'KR2' || refuel?.unitType === 'KR4'"
                          severity="error">
            Electric Unit can not be refuelled. Please enter genset number.
          </inline-message>
        </div>
        <div class="w-1/2">
          <label for="equipment-type" class="block mb-1 text-color text-base">Equipment Type</label>
          <Select class="w-full" v-model="refuel.equipmentType" :options="equipmentTypeOptions" option-label="name"
                  option-value="value" name="equipment-type" id="equipment-type"/>
        </div>
      </div>
      <div class="flex gap-4" v-if=" refuel.equipmentType === 3 || refuel.equipmentType === 4">
        <div class="w-full">
          <label for="electric-number" class="block mb-1 text-color text-base">Attached Electric Unit</label>
          <span class="flex">
           <InputGroup class="w-2/3">
            <InputGroupAddon>
              <i class="pi pi-hashtag mb-2"></i>
            </InputGroupAddon>
            <InputText name="electric-number" v-model.trim="refuel.attachedElectricUnit"
                       @blur="validateAttachedElectricNumber" :invalid="invalidFields.has('attachedElectricUnit')"
                       type="text" class="w-full" id="electric-number"/>
          </InputGroup>
                     <inline-message v-if="invalidFields.has('attachedElectricUnit')"
                                     severity="error">Invalid Unit</inline-message>
         </span>
        </div>
      </div>
      <div class="flex gap-4">
        <div class="w-1/2">
          <label for="fuel-truck" class="block mb-1 text-color text-base">Fuel Truck</label>
          <Select class="w-full" v-model="refuel.truckNumber" :options="fuelTruckOptions" name="fuel-truck"
                  id="fuel-truck"/>
        </div>
        <div v-if="refuel.equipmentType === 1" class="w-1/2">
          <label for="unit-type" class="block mb-1 text-color text-base">Unit Type</label>
          <Select class="w-full" v-model="refuel.unitType" :options="unitTypeOptions"
                  :disabled="!!unitNumberValidation?.dbResponse?.unit_type" name="unit-type" id="unit-type"/>
        </div>
      </div>
      <div class="flex gap-4">
        <div class="w-full">
          <label for="start-fuel" class="block mb-1 mt-2 text-color text-base">Location</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-map-marker text-lg"></i>
            </InputGroupAddon>
            <Select name="location" type="text" v-model="refuel.location" class="w-full"
                    id="location"
                    :options="['TRANSCONA', 'SYMINGTON', 'WIT-PAD', 'SUBWAY', 'FT ROUGE']"/>
          </InputGroup>
        </div>
        <div
            v-if="refuel.equipmentType === 1 || refuel.equipmentType === 2 || refuel.equipmentType === 3 || refuel.equipmentType === 9"
            class="w-full">
          <label for="track" class="block mb-1 mt-2 text-color text-base">Track</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-hashtag text-lg"></i>
            </InputGroupAddon>
            <AutoComplete
                v-model="refuel.track"
                :suggestions="trackSuggestions"
                :optionLabel="(trackSuggestions) => trackSuggestions.track_id"
                :forceSelection="false"
                @complete="searchTracks"
                placeholder="Select track"
            />
          </InputGroup>
        </div>
      </div>

      <div class="flex gap-4">
        <div class="w-full">
          <label for="start-fuel" class="block mb-1 mt-2 text-color text-base">Start Fuel Level</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-gauge text-lg"></i>
            </InputGroupAddon>
            <Select name="start-fuel" type="text" v-model="refuel.startFuelLevel" class="w-full"
                    id="start-fuel"
                    :options="fuelOptions"/>
          </InputGroup>
        </div>

        <div class="w-full">
          <label for="end-fuel" class="block mb-1 mt-2 text-color text-base">End Fuel</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-gauge text-lg"></i>
            </InputGroupAddon>
            <Select name="end-fuel" type="text" v-model="refuel.endFuelLevel" class="w-full" id="end-fuel"
                    :options="fuelOptions.toReversed()"/>
          </InputGroup>
        </div>
        <div class="w-full">
          <label for="fuel-added" class="block mb-1 mt-2 text-color text-base">Fuel Added</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="fa-solid fa-gas-pump mr-2 mb-2"></i>
            </InputGroupAddon>
            <InputNumber name="fuel-added" :minFractionDigits="0" :maxFractionDigits="1"
                         v-model="refuel.fuelAdded" class="w-full" id="fuel-added"/>
            <InputGroupAddon>
              L
            </InputGroupAddon>
          </InputGroup>
        </div>
      </div>
      <div
          v-if="(refuel.equipmentType === 1 && refuel.unitType === 'KR7' || refuel.unitType === 'KH7') || ( refuel.equipmentType === 3 || refuel.equipmentType === 4) && refuel.attachedElectricUnit"
          id="temperature-section">
        <div class="flex gap-4">
          <div class="w-full">
            <label for="setpoint" class="block mb-1 mt-2 text-color text-base">Setpoint</label>
            <InputGroup>
              <InputGroupAddon>
                <i class="fa-solid fa-temperature-three-quarters"></i>
              </InputGroupAddon>
              <InputText name="setpoint" type="text" v-model="refuel.setpoint.rawInput"
                         :invalid="invalidFields.has('setpoint')" @blur="validateTemperatureField('setpoint')"
                         class="w-full" id="setpoint"/>
            </InputGroup>
          </div>
          <div class="w-full">
            <label for="return-temp" class="block mb-1 mt-2 text-color text-base">Return</label>
            <InputGroup>
              <InputGroupAddon>
                <i class="fa-solid fa-temperature-three-quarters"></i>
              </InputGroupAddon>
              <InputText name="return-temp" type="text" v-model="refuel.returnTemp.rawInput"
                         :invalid="invalidFields.has('returnTemp')"
                         @blur="validateTemperatureField('returnTemp')" class="w-full" id="return-temp"/>
            </InputGroup>
          </div>
          <div class="w-full">
            <label for="supply-temp" class="block mb-1 mt-2 text-color text-base">Supply</label>
            <InputGroup>
              <InputGroupAddon>
                <i class="fa-solid fa-temperature-three-quarters"></i>
              </InputGroupAddon>
              <InputText name="supply-temp" type="text" v-model="refuel.supplyTemp.rawInput"
                         :invalid="invalidFields.has('supplyTemp')"
                         @blur="validateTemperatureField('supplyTemp')" class="w-full" id="supply-temp"/>
            </InputGroup>
          </div>
        </div>
        <div class="flex justify-between mt-4">
          <div class="w-1/2">
            <label for="op-mode" class="block mb-1 text-color text-base">Op Mode</label>
            <InputGroup>
              <InputGroupAddon>
                <i class="pi pi-map-marker text-lg"></i>
              </InputGroupAddon>
              <Select name="op-mode" type="text" v-model="refuel.opMode" class="w-full"
                      id="op-mode"
                      :options="['start/stop', 'continuous']"/>
            </InputGroup>
          </div>
          <div class="flex gap-2 mt-7 mr-8">
            <label for="rvr_switch" class="block text-color text-base">Defrost</label>
            <ToggleSwitch v-model="refuel.inDefrost" id="rvr_switch"></ToggleSwitch>
          </div>
        </div>
        <div>
          <label for="alarms" class="block mb-1 mt-2 text-color text-base">Alarms</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-exclamation-triangle mb-2"></i>
            </InputGroupAddon>
            <AutoComplete :multiple="true" v-model="refuel.alarms" :suggestions="alarmSuggestions"
                          :option-label="(alarmSuggestions) => {return alarmSuggestions.manufacturer + ' - ' + alarmSuggestions.alarm_code + ' - ' + alarmSuggestions.alarm_name}"
                          :force-selection="false" @complete="searchAlarms">
              <template #option="slotProps">
                {{ slotProps.option.manufacturer }} - {{ slotProps.option.alarm_code }} - {{
                  slotProps.option.alarm_name
                }}
              </template>
            </AutoComplete>
          </InputGroup>
        </div>
      </div>
      <div>
        <label for="notes" class="block mb-1 text-color text-base">Notes</label>
        <InputGroup>
          <InputGroupAddon>
            <i class="pi pi-file mb-2"></i>
          </InputGroupAddon>
          <Textarea name="notes" v-model="refuel.notes" class="w-full" id="notes"/>
        </InputGroup>
      </div>
    </form>
    <template #footer>
      <div class="flex gap-4 justify-end border-t border-surface pt-8">
        <Button label="Cancel" @click="visible = false" class="p-button-text"></Button>
        <Button label="Submit" @click="submitRefuel"
                :disabled="!refuel.startFuelLevel || !refuel.endFuelLevel || !refuel.fuelAdded || refuel?.unitType === 'KR1' || refuel?.unitType === 'KR2' || refuel?.unitType === 'KR4' || invalidFields.has('attachedElectricUnit') || !refuel.truckNumber || !refuel.equipmentType || (refuel.unitType === 'KR7' && !refuel.opMode)"
                class="p-button-rounded"></Button>
      </div>
    </template>
  </Dialog>

</template>

<style scoped>

</style>