<script setup lang="ts">
import {onMounted, ref} from "vue";
import backendCall from "../../services/AxiosService";

const staffingData = ref();

const fetchWhosIn = async () => {
  const fetchWhoisInCall = await backendCall.get("/staffing/whos-in");
  staffingData.value = fetchWhoisInCall?.data?.data;
}

onMounted(async () => {
  await fetchWhosIn()
})
</script>

<template>
  <div class="bg-surface-0 dark:bg-surface-900 p-6 shadow">
    <div class="mb-4 flex items-center justify-between">
      <div></div>
      <span class="text-xl font-medium text-surface-900 dark:text-surface-0">Who's In</span>
      <span></span>
    </div>
    <div v-if="staffingData" class="border-2 border-solid p-1 border-opacity-100 rounded-xl border-surface">
      <DataTable :value="staffingData" paginator :rows="10" :rows-per-page-options="[10, 20, 50, 100]" data-key="id">
        <Column field="first_name" header="First Name"></Column>
        <Column field="last_name" header="Last Name"></Column>
        <Column header="status">
          <template #body="slotProps">
            <Tag v-if="slotProps.data.status === 'In'">    {{slotProps.data.status}}    </Tag>
            <Tag v-else-if="slotProps.data.status === 'Out'" severity="danger" >{{slotProps.data.status}}</Tag>
          </template>
        </Column>
        <Column header="Shift Started At">
          <template #body="slotProps">
            <div v-if="slotProps.data.status === 'In'">
              {{slotProps.data.time_stamp}}
            </div>
          </template>
        </Column>
        <Column header="Shift Ended At">
          <template #body="slotProps">
            <div v-if="slotProps.data.status === 'Out'">
              {{slotProps.data.time_stamp}}
            </div>
          </template>
        </Column>
<!--        <Column header="Relief Required Before">-->
<!--          <template #body="slotProps">-->
<!--            <div v-if="slotProps.data.status === 'In'">-->
<!--              {{ (() => {-->
<!--     // iife to calculate the relief time and print it in the column.-->
<!--              let reliefTime = new Date(slotProps.data.time_stamp);-->
<!--              reliefTime.setHours(reliefTime.getHours() + 8);-->
<!--              return reliefTime.toLocaleString('en-US', {-->
<!--                hour: '2-digit',-->
<!--                minute: '2-digit',-->
<!--                year: 'numeric',-->
<!--                month: 'long',-->
<!--                day: 'numeric',-->
<!--                hourCycle: 'h23'-->
<!--              });-->
<!--            })() }}-->
<!--            </div>-->
<!--          </template>-->
<!--        </Column>-->
      </DataTable>
    </div>
  </div>
</template>

<style scoped>

</style>