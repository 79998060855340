<script setup>
import {onMounted, reactive, ref, watch} from 'vue';
import backendCall from "../../../services/AxiosService";
import {useToast} from "primevue/usetoast";
const toast = useToast();
const visible = defineModel();

const props = defineProps({subscribeUnit: Object});
const emit = defineEmits(['clearSubscribeUnit']);

const subscribableStations = ref(null);
const selectedStation = ref(null);

const payload = reactive({station: selectedStation, unit: props.subscribeUnit});

watch(() => props.subscribeUnit, (newVal) => {
  payload.unit = newVal;
}, { immediate: true });

const submitSubscription = async () => {
  try {
  const submitSubscriptionCall = await backendCall.post('/units/subscribe', payload);
  if (!submitSubscriptionCall) {
    throw new Error('Could not subscribe to unit.');
  }
   if (submitSubscriptionCall.status === 200 && submitSubscriptionCall.data.success === true) {
     emit("clearSubscribeUnit");

     toast.add({
       severity: 'success',
       summary: 'Success!',
       detail: `Subscribed to ${props.subscribeUnit.equipment_id || props.subscribeUnit.unit_number}`,
       life: 3000
     });
     visible.value = false;
   }

  } catch (e) {
    toast.add({severity: 'error', summary: 'Error!', detail: 'Could not subscribe to unit.', life: 5000});
  }
}

onMounted(async() => {
  const subscribableStationsCall = await backendCall.get('/units/available-subscribe-stations');
  subscribableStations.value = subscribableStationsCall.data.data.stations;

})

</script>

<template>

  <Dialog v-model:visible="visible" :modal="true" :closable="false" :showHeader="false" :breakpoints="{'960px':'75vw','640px': '100vw'}" :style="{width: '40vw', height: '22rem'}">
    <section class="flex flex-col w-full mt-6">
      <div class="flex w-full justify-between mb-6">
        <span class="w-16 h-16 rounded-full flex justify-center items-center bg-orange-100"><i class="pi pi-bell text-orange-700 text-2xl"></i></span>
        <Button type="button" icon="pi pi-times" class="p-button-rounded p-button-secondary p-button-text self-start" @click="visible = false"></Button>
      </div>
      <p class="font-semibold text-xl mt-0 mb-2 text-surface-900 dark:text-surface-0">Subscribe to Unit</p>
      <p class="font-normal text-base mt-0 mb-4 text-surface-600 dark:text-surface-200">Select the station you would like to receive a notification at.</p>
      <Dropdown :options="subscribableStations" key="splc" filter filter-placeholder="Search" autoFilterFocus v-model="selectedStation" option-label="station_name" appendTo="body" styleClass="w-full rounded-lg" placeholder="Station - Subdivision">
        <template #value="slotProps">
          <div v-if="slotProps.value" class="flex items-center">
            <p class="mt-0 mb-0 font-normal text-base text-surface-600 dark:text-surface-200">{{slotProps.value.station_name + ' - ' + slotProps.value.subdivision}}</p>
          </div>
        </template>
        <template #option="slotProps">
          <div v-if="slotProps.option" class="flex items-center">
            <p class="mt-0 mb-0 font-normal text-base text-surface-600 dark:text-surface-200">{{slotProps.option.station_name + ' - ' + slotProps.option.subdivision}}</p>
          </div>
        </template>
      </Dropdown>
    </section>
    <template #footer>
      <div class="pt-4 flex">
        <Button @click="visible = false" label="Cancel" class="p-button-text grow"></Button>
        <Button @click="submitSubscription" label="Subscribe" class="grow"></Button>
      </div>
    </template>
  </Dialog>

</template>

<style scoped>

</style>