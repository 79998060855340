<script setup>
import {onMounted, ref} from "vue";
import NewRefuelDialog from "../../components/fuel/NewRefuelDialog.vue";
import RefuelInfoDialog from "../../components/fuel/RefuelInfoDialog.vue";
import backendCall from "../../services/AxiosService";
const newRefuelModalVisible = ref(false);
const refuelList = ref([]);

const refuelInfoDialogVisible = ref(false);
const selectedRefuel = ref({});

const openRefuelInfoDialog = (refuelData) => {
  selectedRefuel.value = refuelData;
  refuelInfoDialogVisible.value = true;
}

const handleNewRefuelSubmitted = () => {
  newRefuelModalVisible.value = false;
  fetchRefuels()
}

const fetchRefuels = async () => {
  const refuelQuery = await backendCall.get('fuel');
  refuelList.value = refuelQuery.data.data.refuels;
}
const refuelTable = ref();

const exportCSV = () => {
  refuelTable.value.exportCSV();
}

onMounted(async()=>{
  await fetchRefuels()
})

</script>

<template>
  <div class="bg-surface-0 dark:bg-surface-900 p-6 shadow rounded-border">
    <div class="mb-4 flex items-center justify-between">
      <span></span>
      <span class="text-xl font-medium text-surface-900 dark:text-surface-0">Refueling Log</span>
      <span>
      <Button class="mx-2" label="New" icon="pi pi-plus" @click="newRefuelModalVisible = true"></Button>
<!--        <Button class="mx-2" icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />-->
        </span>
    </div>
    <div class="border-2 border-solid rounded-xl p-1 border-surface">
      <DataTable ref="refuelTable" :value="refuelList" paginator :rows="25" :rows-per-page-options="[25, 50, 100, 500]">
        <Column field="refuel_id" header="ID"></Column>
        <Column field="equipment_id" header="Unit"></Column>
        <Column field="date_time" header="Time">
          <template #body="slotProps">
            {{new Date(slotProps.data.date_time).toLocaleString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hourCycle: 'h23'
          })}}
          </template>
        </Column>
        <Column field="start_fl" header="Start Level"></Column>
        <Column field="end_fl" header="End Level"></Column>
        <Column field="fuel_added" header="Liters Added">
          <template #body="slotProps">
            {{slotProps.data?.fuel_added}}{{slotProps.data?.fuel_added ? "L" : ""}}
          </template>
        </Column>
        <Column header="Actions">
          <template #body="slotProps">
            <Button label="View" outlined @click="openRefuelInfoDialog(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
      <NewRefuelDialog v-if="newRefuelModalVisible" v-model:visible="newRefuelModalVisible" @submitted="handleNewRefuelSubmitted"></NewRefuelDialog>
    </div>
  </div>

  <RefuelInfoDialog v-model:refuel-info-dialog-visible="refuelInfoDialogVisible" :refuelInfo="selectedRefuel"></RefuelInfoDialog>

</template>

<style scoped>

</style>