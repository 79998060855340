// stores/authStore.js
import { defineStore } from 'pinia';
import backendCall from "../services/AxiosService";

export const useAuthStore = defineStore('auth', {
    state: () => ({
        isAuthenticated: false,
        user: null,
        authCheckPromise: null
    }),
    actions: {
        async checkAuthStatus() {
            if (this.authCheckPromise) {
                return this.authCheckPromise;
            }

            this.authCheckPromise = backendCall.get('/auth/check')
                .then(response => {
                    this.isAuthenticated = !!response.data.user;
                    this.user = response.data.user;
                })
                .catch(() => {
                    this.isAuthenticated = false;
                    this.user = null;
                })
                .finally(() => {
                    this.authCheckPromise = null;
                });

            return this.authCheckPromise;
        },
        async login(credentials) {
            const response = await backendCall.post('/auth/login', credentials);
            this.isAuthenticated = true;
            this.user = response.data.user;

        },
        async logout() {
            await backendCall.get('/auth/logout');
            this.isAuthenticated = false;
            this.user = null;
        }
    }
});