<template>
  <FieldUnitWaybillInfoDialog v-model="infoDialogVisible" :unit-number="unitNumber" :description="requestDescription"
                              :is-request="isRequest"/>

  <Dialog v-model:visible="notesDialogVisible" modal :header="`${unitNumber} Notes`" :style="{ width: '25rem' }">
    <span class="text-surface-500 dark:text-surface-400 block mb-8">Add Notes for {{ unitNumber }}</span>
    <div class="flex items-center gap-4 mb-4">
      <label for="notes" class="font-semibold w-24">Notes</label>
      <Textarea id="notes"></Textarea>
    </div>
    <div class="flex justify-end gap-2">
      <Button type="button" label="Cancel" severity="secondary" @click="visible = false"></Button>
      <Button type="button" label="Save" @click="visible = false"></Button>
    </div>
  </Dialog>

  <Dialog v-model:visible="alarmsDialogVisible" modal :header="`${unitNumber} Alarms`" :style="{ width: '40rem' }">
    <span class="text-surface-500 dark:text-surface-400 block mb-8">Record Alarms for {{ unitNumber }}</span>
    <div class="flex items-center gap-4 mb-4">
      <label for="notes" class="font-semibold w-24">Notes</label>
      <AutoComplete :multiple="true" v-model="unitInfo.alarms" :suggestions="alarmSuggestions"
                    :option-label="(alarmSuggestions) => {return alarmSuggestions.manufacturer + ' - ' + alarmSuggestions.alarm_code + ' - ' + alarmSuggestions.alarm_name}"
                    :force-selection="false" @complete="searchAlarms">
        <template #option="slotProps">
          {{slotProps.option.manufacturer}} - {{ slotProps.option.alarm_code }} - {{ slotProps.option.alarm_name }}
        </template>
      </AutoComplete>
    </div>
    <div class="flex justify-end gap-2">
      <Button type="button" label="Cancel" severity="secondary" @click="alarmsDialogVisible = false"></Button>
      <Button type="button" label="Save" @click="alarmsDialogVisible = false"></Button>
    </div>
  </Dialog>

  <div class="col-span-12 md:col-span-6 xl:col-span-4 p-4">
    <div class="bg-surface-0 dark:bg-surface-900 shadow rounded-xl border-2 r p-6">
      <div class="border-b border-surface pb-6 justify-between">
        <!--        <img src="/images/blocks/avatars/circle/avatar-f-1.png" style="width: 70px; height: 70px" class="mr-4"/>-->
        <div class="flex flex-col">
          <div class="flex justify-between">
            <div class="flex flex-col">
              <div class="text-xl text-surface-900 dark:text-surface-0 font-medium mb-1">{{ unitNumber }}</div>
              <div class="text-surface-500 dark:text-surface-300">{{ clipNumber }}</div>
              <div class="my-2 flex items-center">
                <span class="text-surface-500 dark:text-surface-300 font-medium"> Setpoint: &nbsp;</span>
                <span class="text-surface-900 dark:text-surface-0 font-bold"
                      :class="billedSetpointMismatch ? 'text-red-500' : null"> {{ setpointString }}</span>
                <span>
              <Button @click="toggleSetpointExceptionMenu($event)" class="ml-2 mt-0" icon="pi pi-exclamation-circle"
                      rounded severity="danger" style="width: 1.5rem; height: 1.5rem">
<!--            <i class="pi pi-exclamation-circle al" style="width: 0.5rem"></i>-->
              </Button>
                  <Menu ref="setpoint_exception_menu" class="mt-2" appendTo="body" popup
                        :model="setpointExceptionMenuItems"></Menu>
          </span>
              </div>
            </div>
            <div class="flex flex-col">
            <div class="mb-2">
              <span class="text-surface-500 dark:text-surface-300 font-medium mb-1">SEQ: &nbsp;</span>
              <span class="text-surface-600 dark:text-surface-200 font-medium"> {{ sequence }} </span>
            </div>
              <div v-if="unitInfo.doa" class="font-bold text-xl text-red-700">DOA</div>
              <div v-if="unitInfo.alarms.length > 0" class="font-bold text-surface-500 dark:text-surface-300">Alarms</div>
              <div v-for="alarm of unitInfo.alarms" class="text-red-600 font-bold">
                {{alarm.alarm_code}}
              </div>
            </div>
            <div>
              <Button @click="toggleExceptionMenu($event)" rounded icon="pi pi-exclamation-circle"
                      severity="warn"/>
              <Menu ref="exception_menu" class="mt-2" appendTo="body" popup :model="exceptionMenuItems"></Menu>
            </div>
          </div>
          <div class="flex">
            <div class="flex flex-col col-span-6">
              <!--          <span class="bg-blue-50 text-blue-400 rounded-border inline-flex py-1 px-2 text-sm">Editor</span>-->
              <div class="mb-2 mr-4">
                <label for="return-temp-input" class="text-surface-500 dark:text-surface-300">Return Temp: </label>
                <InputNumber v-model="unitInfo.returnTemp"
                             :suffix="unitType === 'domestic' || unitType === 'DIESEL' ? 'F' : 'C'"
                             id="return-temp-input" :min="-40" :max="99" :minFractionDigits="1" :maxFractionDigits="1"
                             :inputProps="{ inputmode: 'numeric' }" :input-style="{width:'5rem'}"/>
              </div>
              <div class="my-2">
                <label for="supply-temp-input" class="text-surface-500 dark:text-surface-300">Supply Temp: </label>
                <InputNumber v-model="unitInfo.supplyTemp"
                             :suffix="unitType === 'domestic' || unitType === 'DIESEL' ? 'F' : 'C'"
                             id="supply-temp-input" :min="-40" :max="99" :minFractionDigits="1" :maxFractionDigits="1"
                             :inputProps="{ inputmode: 'numeric' }" :input-style="{width:'5rem'}"/>
              </div>
            </div>

            <div class="flex flex-col col-span-6 gap-4">


              <div class="flex flex-col gap-4" v-if="unitType === 'domestic' || clipNumber">
                <InputGroup>
                  <InputGroupAddon><i class="pi pi-gauge"></i></InputGroupAddon>
                  <Select name="start-fuel" type="text" v-model="unitInfo.startFuelLevel"
                            id="start-fuel"
                            :options="fuelOptions" style="width: 6rem;"/>
                </InputGroup>

                <InputGroup>
                  <InputNumber v-model="unitInfo.fuelAdded" placeholder="+ Fuel" :min="1" :max="9999"
                               :inputProps="{ inputmode: 'numeric' }" :minFractionDigits="1" :maxFractionDigits="1"
                               :input-style="{width:'5rem'}"/>
                  <InputGroupAddon>L</InputGroupAddon>
                </InputGroup>
                <div class="gap-4 mb-3" v-if="unitInfo.fuelAdded">
                  <InputGroup>
                    <InputGroupAddon><i class="pi pi-gauge"></i></InputGroupAddon>
                    <Dropdown name="end-fuel" type="text" v-model="unitInfo.endFuelLevel"
                              id="end-fuel"
                              :options="fuelOptions.toReversed()" style="width: 6rem;"/>
                  </InputGroup>
                </div>
              </div>
            </div>
          </div>
          <SelectButton v-if="unitType === 'domestic' || unitType === 'DIESEL'"
                        class="bg-surface-50 dark:bg-surface-950 text-surface-100 self-center"
                        v-model="unitInfo.opModeValue"
                        :options="['Start/Stop', 'Continuous']" aria-labelledby="basic"/>
          <p v-if="isRequest">{{ requestDescription }}</p>
        </div>
      </div>
      <div class="flex justify-between pt-6">
        <Button @click="infoDialogVisible=true" label="Info" icon="pi pi-info-circle"
                class="p-button-outlined p-button-secondary w-6/12 mr-2"></Button>
        <Button @click="submitUnitInfo" label="Submit" icon="pi pi-check"
                class="p-button-outlined p-button-secondary w-6/12 ml-2"></Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import {onMounted, Ref, ref} from "vue";
import InputGroupAddon from "primevue/inputgroupaddon";
import InputGroup from "primevue/inputgroup";
import {useToast} from "primevue/usetoast";
import Menu from 'primevue/menu';
import FieldUnitWaybillInfoDialog from "../../components/trains/FieldUnitWaybillInfoDialog.vue";


const toast = useToast();
const trainArrived = ref(false);
const waitTimeStarted = ref(false);
const timeStarted = ref(false);
const protectionStarted = ref(false);
const protectionEnded = ref(false);
const protectionTrack = ref(null);
const trainComplete = ref(false);
const infoDialogVisible = ref(false);
const notesDialogVisible = ref(false);
const alarmsDialogVisible = ref(false);

interface UnitInfo {
  unitNumber: string,
  returnTemp: number | null,
  supplyTemp: number | null,
  setpoint: number,
  tempUnit: "F" | "C",
  startFuelLevel: number | null,
  endFuelLevel: number | null,
  fuelAdded: number | null,
  opModeValue: string | null,
  waybillId: number
}

const emit = defineEmits(['remove', 'submitUnitInfo']);


const setpoint_exception_menu = ref<InstanceType<typeof Menu> | null>(null);

const exception_menu = ref<InstanceType<typeof Menu> | null>(null);

const toggleSetpointExceptionMenu = (event: Event) => {
  setpoint_exception_menu.value?.toggle(event);
};

const toggleExceptionMenu = (event: Event) => {
  exception_menu.value?.toggle(event);
};

const toggleTrainArrived = () => {
  trainArrived.value = !trainArrived.value;
}

const toggleBeginTime = () => {
  timeStarted.value = !timeStarted.value;
}

const toggleWaitTime = () => {
  waitTimeStarted.value = !waitTimeStarted.value;
}

const endProtection = () => {
  protectionStarted.value = false;
  protectionEnded.value = true;
  protectionTrack.value = null;
}

const startProtection = () => {
  protectionStarted.value = true;
  protectionEnded.value = false;
}

const props = defineProps({
  unitNumber: String,
  setpointString: String,
  setpointCelsius: Number,
  setpointFahrenheit: Number,
  sequence: Number,
  unitType: String,
  clipNumber: String,
  waybillId: Number,
  isRequest: Boolean,
  requestDescription: String,
  alarms: Array
})

let tempUnit: "C" | "F";

if (props.unitType === 'domestic' || props.unitType === 'DIESEL') {
  tempUnit = "F"
} else {
  tempUnit = "C"
}

const exceptionMenuItems = ref([
  {
    label: 'Dead On Arrival',
    icon: 'pi pi-exclamation-circle',
    command: () => {
      unitInfo.value.doa = !unitInfo.value.doa;
    }
  },
  {
    label: 'Record Alarms',
    icon: 'pi pi-exclamation-circle',
    command: () => {
      alarmsDialogVisible.value = true;
    }
  },

  {
    label: 'Add Note',
    icon: 'pi pi-pen-to-square',
    command: () => {
      notesDialogVisible.value = true;
    }
  }
]);

const setpointExceptionMenuItems = ref([

  ...props.setpointCelsius - Math.floor(props.setpointCelsius) !== 0 ? [{
    label: `${Math.ceil(props.setpointCelsius)}`,
    icon: 'pi pi-arrow-up',
    command: () => {
      billedSetpointMismatch.value = !billedSetpointMismatch.value;
      toast.add({severity: 'warn', summary: 'Search Completed', detail: 'No results found', life: 3000});
    }
  },
    {
      label: `${Math.floor(props.setpointCelsius)}`,
      icon: 'pi pi-arrow-down',
      command: () => {
        billedSetpointMismatch.value = !billedSetpointMismatch.value;
        toast.add({severity: 'warn', summary: 'Search Completed', detail: 'No results found', life: 3000});
      }
    }] : [],
  {
    label: 'Other',
    icon: 'pi pi-pen-to-square',
    command: () => {
      toast.add({severity: 'warn', summary: 'Search Completed', detail: 'No results found', life: 3000});
    }
  }
]);

const fuelOptions: Ref = ref(['E',
  '1/8',
  '1/4',
  '3/8',
  '5/16',
  '7/16',
  '1/2',
  '9/16',
  '5/8',
  '3/4',
  '13/16',
  '7/8',
  'F'])


const unitInfo: Ref<UnitInfo> = ref({
  unitNumber: null,
  returnTemp: null,
  supplyTemp: null,
  setpoint: null,
  tempUnit: tempUnit,
  startFuelLevel: null,
  endFuelLevel: null,
  fuelAdded: null,
  opModeValue: null,
  waybillId: null,
  alarms: [],
  doa: false
});

// Initialize as an empty array
const alarmSuggestions = ref<string[]>([]);
const allAlarms = ref(props.alarms);



const searchAlarms = (event: { query: string }) => {
  const query = event.query.trim().toLowerCase();

  if (query.length === 0) {
    alarmSuggestions.value = [...allAlarms.value];
  } else {
    alarmSuggestions.value = allAlarms.value.filter((alarm) =>
        alarm.alarm_code.toLowerCase().includes(query) || alarm.alarm_name.toLowerCase().includes(query)
    );
  }
};

const billedSetpointMismatch = ref(false);

const submitUnitInfo = () => {
  unitInfo.value.unitNumber = props.unitNumber;
  unitInfo.value.waybillId = props.waybillId;
  unitInfo.value.setpoint = props.unitType === 'domestic' || props.unitType === 'DIESEL' ? props.setpointFahrenheit : props.setpointCelsius;
  console.log(props.waybillId);
  emit('submitUnitInfo', unitInfo.value);
}

onMounted(async() => {
})


</script>

<style scoped>

</style>