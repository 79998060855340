<template>
  <Dialog :visible="props.visible" @update:visible="$emit('visible-change')" modal header="New Request"
          :style="{ width: '40rem' }">
    <div class="bg-white dark:bg-neutral-900 mt-4 p-6 rounded-lg shadow-md">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div class="col-span-1">
          <label for="unit_number" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Unit Number:</label>
          <InputText v-model="form.unit_number" id="unit_number" placeholder="Unit Number" @change="fetchWaybills"
                     class="w-full"/>
        </div>
        <div class="col-span-1">
          <label for="billed_setpoint" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Setpoint:</label>
          <InputText v-model="form.billed_setpoint" id="billed_setpoint" placeholder="Setpoint"
                     class="w-full"/>
        </div>
        <div class="col-span-2">
          <hr class="border-t border-gray-300 dark:border-gray-600 my-4"/>
        </div>
        <div class="col-span-2">
          <label for="unit_location_div" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Location:</label>
          <div id="unit_location_div" class="text-gray-900 dark:text-gray-100">{{ unitLocation }}</div>
        </div>
        <div class="col-span-2">
          <hr class="border-t border-gray-300 dark:border-gray-600 my-4"/>
        </div>
        <div v-if="showWaybillSection" class="col-span-2">
          <label for="waybill_dropdown" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Waybill:</label>
          <Dropdown v-model="form.waybill" id="waybill_dropdown" :options="waybills" optionLabel="label"
                    placeholder="Select Waybill" @change="toggleTrainInfoSection" class="w-full"/>
        </div>
        <div class="col-span-2">
          <hr class="border-t border-gray-300 dark:border-gray-600 my-4"/>
        </div>
        <div v-if="showTrainInfoSection" class="col-span-2 grid grid-cols-1 md:grid-cols-2 gap-6">
          <div class="col-span-1">
            <label for="train_id" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Train ID:</label>
            <InputText v-model="form.train_id" id="train_id" placeholder="Train Id" class="w-full"/>
          </div>
          <div class="col-span-1">
            <label for="train_origin_departure_date" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Origin Departure Date:</label>
            <Calendar v-model="form.train_origin_departure_date" id="train_origin_departure_date"
                      dateFormat="yy-mm-dd" class="w-full"/>
          </div>
          <div class="col-span-1">
            <label for="eta_to_midpoint" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">ETA To Midpoint:</label>
            <Calendar v-model="form.eta_to_midpoint" id="eta_to_midpoint" showTime dateFormat="yy-mm-dd" class="w-full"/>
          </div>
          <div class="col-span-1">
            <label for="railway" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Railway:</label>
            <Dropdown v-model="form.railway" id="railway" :options="railways" placeholder="Select Railway" class="w-full"/>
          </div>
        </div>
        <div class="col-span-2">
          <hr class="border-t border-gray-300 dark:border-gray-600 my-4"/>
        </div>
        <div class="col-span-1">
          <label for="sequence-input" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Sequence:</label>
          <InputText v-model="form.sequence" id="sequence-input" placeholder="Sequence" class="w-full"/>
        </div>
        <div class="col-span-1">
          <label for="track" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Track:</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-hashtag text-lg"></i>
            </InputGroupAddon>
            <AutoComplete
                v-model="form.track"
                :suggestions="trackSuggestions"
                :optionLabel="(trackSuggestions) => trackSuggestions.track_id"
                :forceSelection="false"
                @complete="searchTracks"
                placeholder="Select track"
            />
          </InputGroup>
        </div>
        <div class="col-span-2">
          <hr class="border-t border-gray-300 dark:border-gray-600 my-4"/>
        </div>
        <div class="col-span-1">
          <label for="payer-input" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Payer:</label>
          <Dropdown v-model="form.payer" id="payer-input" :options="payers" optionLabel="name"
                    placeholder="Select Payer" class="w-full"/>
        </div>
        <div class="col-span-1">
          <label for="leg-input" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Leg:</label>
          <InputText v-model="form.leg" id="leg-input" placeholder="Leg" class="w-full"/>
        </div>
        <div class="col-span-2">
          <hr class="border-t border-gray-300 dark:border-gray-600 my-4"/>
        </div>
        <div class="col-span-2 flex justify-between items-center">
          <div class="flex items-center">
            <Checkbox v-model="form.fuel_request" id="fuel_request" :binary="true"/>
            <label for="fuel_request" class="ml-2 block text-sm text-gray-900 dark:text-gray-300">Fuel Request</label>
          </div>
          <div class="flex items-center">
            <Checkbox v-model="form.repair_request" id="repair_request" :binary="true"/>
            <label for="repair_request" class="ml-2 block text-sm text-gray-900 dark:text-gray-300">Repair Request</label>
          </div>
          <div class="flex items-center">
            <Checkbox v-model="form.check_request" id="check_request" :binary="true"/>
            <label for="check_request" class="ml-2 block text-sm text-gray-900 dark:text-gray-300">Check Request</label>
          </div>
        </div>
        <div class="col-span-2">
          <hr class="border-t border-gray-300 dark:border-gray-600 my-4"/>
        </div>
        <div class="col-span-2">
          <label for="bio2" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Notes</label>
          <Textarea v-model="form.notes" id="bio2" rows="5" autoResize class="w-full"/>
        </div>
        <div class="col-span-2">
          <hr class="border-t border-gray-300 dark:border-gray-600 my-4"/>
        </div>
        <div class="col-span-2">
          <Button label="Submit" class="w-full"/>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import {ref, reactive, onMounted, watch} from 'vue';
import Calendar from 'primevue/calendar';
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import {useReferenceStore} from "../../stores/referenceStore";

const referenceStore = useReferenceStore();

const props = defineProps({
  visible: Boolean
})
const emits = defineEmits(["visible-change"]);

const form = reactive({
  unit_number: '',
  waybill: '',
  train_id: '',
  train_origin_departure_date: '',
  eta_to_midpoint: null,
  railway: '',
  sequence: '',
  track: '',
  billed_setpoint: '',
  billed_setpoint_temp_unit: '',
  payer: '',
  leg: '',
  fuel_request: false,
  repair_request: false,
  check_request: false,
  request_notes: ''
});

const waybills = ref([]);
const payers = ref([
  {id: 1, name: 'CN'},
  {id: 2, name: 'TransX'}
]);

const railways = ref(['CN']);

const unitLocation = ref('');
const showWaybillSection = ref(true);
const showTrainInfoSection = ref(true);

const fetchWaybills = () => {
  // Simulate API call
  setTimeout(() => {
    waybills.value = [
      {waybill_id: 1, waybill_time: new Date(), railway: 'CN', train_id: '123456'},
      {waybill_id: 2, waybill_time: new Date(), railway: 'CP', train_id: '654321'}
    ];
  }, 500);
};

const waybillDisplay = (waybill) => {
  const waybillDate = new Date(waybill.waybill_time);
  const dashedTrainId = waybill.train_id.slice(0, waybill.train_id.length - 2) + '-' + waybill.train_id.slice(waybill.train_id.length - 2);
  return `${waybill.waybill_id} - ${waybill.railway} - ${dashedTrainId} - ${waybillDate.toDateString()}`;
};

const toggleTrainInfoSection = () => {
  showTrainInfoSection.value = form.waybill === 'new_waybill';
};

const handleSubmit = () => {
};

const trackSuggestions = ref<string[]>([]);
const allTracks = ref();

watch(() => referenceStore.tracks, (newValue) => {
  allTracks.value = newValue;
});


const searchTracks = (event: { query: string }) => {
  const query = event.query.trim().toUpperCase();

  if (query.length === 0) {
    trackSuggestions.value = [...allTracks.value];
  } else {
    trackSuggestions.value = allTracks.value.filter((track) =>
        track.track_id.toUpperCase().startsWith(query) || track.track_name.toUpperCase().startsWith(query)
    );
  }
};

onMounted(async () => {
  // await fetchAlarmList()
  if (!allTracks.value) {
    await referenceStore.fetchReferenceData()
  }
})
</script>

<style>
.container {
  padding-top: 2rem;
}

.card {
  padding: 1.5rem;
}

.form-group {
  margin-bottom: 1rem;
}
</style>