<script setup>
import {onBeforeMount, onMounted, ref} from "vue";
import ClipOnList from "../../components/clipons/ClipOnList.vue";
import backendCall from "../../services/AxiosService";
import ClipTimeline from "./ClipTimeline.vue";
import DismountClipDialog from "../../components/clipons/DismountClipDialog.vue";

const dismountClipDialogVisible = ref(false);
const clips = ref();

const fetchClips = async () => {
  const fetchClipsCall = await backendCall.get("/clip-ons");
  clips.value = fetchClipsCall.data.data;
}

onBeforeMount( async () => {

})

onMounted(async() => {
await fetchClips();

})

</script>

<template>
<DismountClipDialog v-model="dismountClipDialogVisible" @update-clips="fetchClips"/>
  <div class="bg-surface-0 dark:bg-surface-900 p-6 shadow rounded-border">
    <div class="mb-4 flex items-center justify-between">
      <span class="text-xl font-medium text-surface-900 dark:text-surface-0">Clip-Ons</span>
      <Button @click="dismountClipDialogVisible = true" outlined label="Dismount" icon="pi pi-wrench"></Button>
    </div>
    <div class="border-2 border-solid rounded-xl border-surface">

      <div class="flex flex-col flex-auto">
        <div class="p-8">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 xl:col-span-12">
              <ClipOnList v-if="clips" title="Failed Pretrip" list-type="failed-pretrip" :clips="clips.failedPretripClips" @update-clips="fetchClips"/>
            </div>
            <div class="col-span-12 xl:col-span-6">
              <ClipOnList v-if="clips" title="Ready to Install" list-type="ready-to-install" :clips="clips.clipsReadyToInstall" @update-clips="fetchClips"/>
            </div>
            <div class="col-span-12 xl:col-span-6">
              <ClipOnList v-if="clips" title="Pretrip Required" list-type="pretrip-required" :clips="clips.clipsUnpretripped" @update-clips="fetchClips"/>
            </div>
            <ClipTimeline v-if="clips" :clip-events="clips.clipEvents" :clip-events-limit="clips.clipEventsLimit"/>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>