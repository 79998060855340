<script setup  lang="ts">
import {ref} from "vue";
import backendCall from "../../services/AxiosService";
import {useToast} from "primevue/usetoast";
import router from "../../router";


const props = defineProps({visible: Boolean});
const emit = defineEmits(['visible-change','fetch-yard-checks']);
const toast = useToast();



interface NewYardCheckData {
  date: Date,
  morningAfternoon: 'morning' | 'afternoon' | null
}

const newYardCheckData = ref({
  date: new Date(),
  morningAfternoon: null,
});

const timeOptions = ref([
  { name: 'Morning', code: 'morning' },
  { name: 'Afternoon', code: 'afternoon' },
  { name: 'Other', code: 'other' },
]);

const isSubmitting = ref(false);

const submitNewYardCheck = async () => {
  try {
    if (isSubmitting.value) return
    if (newYardCheckData.value.date && newYardCheckData.value.morningAfternoon) {
      isSubmitting.value = true;
      console.log(newYardCheckData.value);
      const submitYardCheckCall = await backendCall.post('yard-check', newYardCheckData.value);
      if (!submitYardCheckCall) {
        throw new Error('API Error')
      }
      const newYardCheckId = submitYardCheckCall?.data?.data?.yardCheckId;
      console.log(newYardCheckId);
      newYardCheckData.value.date = new Date();
      newYardCheckData.value.morningAfternoon = null;
      emit('visible-change');
      emit('fetch-yard-checks')
     await router.push(`/yard-check/${newYardCheckId.toString()}/perform`);
    }
  } catch (e) {
    toast.add({ severity: 'error', summary: 'Could not add Yard Check!', detail: 'An error occured while adding the Yard Check.', life: 5000 });

  } finally {
    isSubmitting.value = false;
  }
}

</script>

<template>
  <Dialog :visible="props.visible" @update:visible="$emit('visible-change')" appendTo="body" modal
          :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '42vw'}" header="Edit Payment Method"
          :draggable="false" :resizable="false">
    <template #header>
      <div class="flex flex-col gap-2">
        <h1 class="m-0 text-surface-900 dark:text-surface-0 font-semibold text-xl leading-normal">New Yard Check</h1>
        <span class="text-surface-600 dark:text-surface-200 text-base">Create New Yard Check</span>
      </div>
    </template>
    <form class="flex flex-col gap-4 mt-4">
      <div class="flex gap-4">
        <div class="w-full">
          <label for="date" class="block mb-1 text-color text-base">Date</label>
          <Calendar id="date" v-model="newYardCheckData.date" :invalid="!newYardCheckData.date" dateFormat="mm/dd/yy" class="w-full"/>
        </div>
        <div class="w-full">
          <label for="eta" class="block mb-1 text-color text-base">Morning / Afternoon</label>
          <span class="p-input-icon-left w-full">
                    <Dropdown v-model="newYardCheckData.morningAfternoon" :options="timeOptions" optionLabel="name" optionValue="code" placeholder="Morning / Afternoon?" class="w-full md:w-56" />

          </span>
        </div>
      </div>
    </form>
    <template #footer>
      <div class="flex gap-4 justify-end border-t border-surface pt-8">
        <Button label="Cancel" @click="$emit('visible-change')" class="p-button-text"></Button>
        <Button label="Submit" :disabled="isSubmitting" @click="submitNewYardCheck" class="p-button-rounded"></Button>
      </div>
    </template>
  </Dialog>

</template>

<style scoped>

</style>