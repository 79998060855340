<script setup>
import { computed } from 'vue';
import {toDashedTrainId} from "../../utilities/toDashedTrainId";


const props = defineProps({
  trainId: String,
  totalElectrics: Number,
  totalGensets: Number,
  totalRequests: Number,
  totalDomestics: Number,
  trainArrivalTime: String,
  totalTime: String,
  totalEmployees: Number,
  totalWaitTime: String,
  workersOnTrainCount: Number,
  workersOnTrain: Array,
})



const dashedTrainId = computed(() => {
if (props?.trainId) {
  return toDashedTrainId(props.trainId);
}
});

</script>


<template>
  <div class="bg-surface-0 dark:bg-surface-950 px-2 py-8 md:px-2 lg:px-2 flex flex-wrap">
    <div class="w-full mb-4 md:w-6/12">
            <span class="inline-flex items-center text-surface-900 dark:text-surface-0 text-3xl font-medium mb-4 cursor-pointer"
            >{{ dashedTrainId }}</span>
      <div class="flex items-center text-surface-600 dark:text-surface-200">
        <span class="mr-4"><i class="pi pi-bolt mr-2" />{{props?.totalGensets}} Gensets</span>
        <span class="mx-4"><i class="pi pi-bolt mr-2" />{{props?.totalElectrics}} Electrics</span>
        <span class="mx-4"><i class="pi pi-exclamation-circle mr-2" />{{props?.totalRequests}} Requests</span>
        <span class="mx-4"><i class="fa-solid fa-gas-pump mr-2"></i>{{props?.totalDomestics}} Domestics</span>
      </div>
    </div>
    <div class="w-full md:w-6/12 flex flex-col md:flex-row md:items-center md:justify-end mt-6 md:mt-0">
      <div class="flex items-center mr-0 md:mr-8">
                <span class="rounded-full flex items-center justify-center bg-cyan-300 text-cyan-800 mr-4 w-14 h-14">
                    <i class="pi pi-users !text-3xl" />
                </span>
        <div>
          <span class="font-medium text-surface-600 dark:text-surface-200">Workers</span>
          <p class="mt-1 mb-0 text-surface-900 dark:text-surface-0 text-2xl font-medium">{{props?.workersOnTrainCount}}</p>
        </div>
      </div>
      <div class="flex items-center mt-4 md:mt-0">
                <span class="rounded-full flex items-center justify-center bg-yellow-300 text-yellow-800 mr-4 w-14 h-14">
                    <i class="pi pi-clock !text-3xl" />
                </span>
        <div>
          <span class="font-medium text-surface-600 dark:text-surface-200">Train Arrival</span>
          <p class="mt-1 mb-0 text-surface-900 dark:text-surface-0 text-2xl font-medium">{{props?.trainArrivalTime ? new Date(props?.trainArrivalTime).toLocaleString('en-US', {month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', hourCycle: 'h23',  dateStyle: undefined, timeStyle: undefined}) : '- - - -'}}</p>
        </div>
      </div>
    </div>

  <div class="bg-surface-50 w-full dark:bg-surface-950">
    <div class="bg-surface-50 dark:bg-surface-950 px-2 py-1 md:px-2 lg:px-2">
      <div class="grid grid-cols-12 gap-8">
        <div class="col-span-12 md:col-span-6 lg:col-span-4 p-2">
          <div class="p-4 text-center bg-blue-500 rounded-xl">
                        <span class="inline-flex justify-center items-center bg-blue-600 rounded-full mb-4 w-12 h-12 py-2">
                            <i class="pi pi-clock !text-xl leading-none text-white" />
                        </span>
            <div class="text-2xl font-medium text-white mb-2">{{props?.totalTime ?? 0}}</div>
            <span class="text-blue-100 font-medium">Total Billing Time</span>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6 lg:col-span-4 p-2">
          <div class="p-4 text-center bg-purple-500 rounded-xl">
                        <span class="inline-flex justify-center items-center bg-purple-600 rounded-full mb-4 w-12 h-12 py-2">
                            <i class="pi pi-clock !text-xl leading-none text-white" />
                        </span>
            <div class="text-2xl font-medium text-white mb-2">{{props?.totalWaitTime ?? 0}}</div>
            <span class="text-purple-100 font-medium">Total Wait Time</span>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6 lg:col-span-4 p-2">
          <div class="p-4 text-center bg-indigo-500 rounded-xl">
                        <span class="inline-flex justify-center items-center bg-indigo-600 rounded-full mb-2 w-12 h-12 py-2">
                            <i class="pi pi-clock !text-xl leading-none text-white" />
                        </span>
            <div class="text-2xl font-medium text-white mb-2">{{props?.trainArrivalTime ? new Date(props?.trainArrivalTime).toLocaleString('en-US', {month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', hourCycle: 'h23',  dateStyle: undefined, timeStyle: undefined}) : '- - - -'}}</div>
            <span class="text-indigo-100 font-medium">Train Arrival Time</span>
          </div>
        </div>
<!--        <div class="col-span-12 md:col-span-6 lg:col-span-3 p-2">-->
<!--          <div class="p-4 text-center bg-orange-500 rounded-xl">-->
<!--                        <span class="inline-flex justify-center items-center bg-orange-600 rounded-full mb-4 w-12 h-12 py-2">-->
<!--                            <i class="pi pi-users !text-xl leading-none text-white" />-->
<!--                        </span>-->
<!--            <div class="text-2xl font-medium text-white mb-2">{{props?.workersOnTrainCount}}</div>-->
<!--            <span class="text-orange-100 font-medium">Workers On Train</span>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
  </div>
</template>
