<script setup lang="ts">
import {onMounted, ref, watch} from "vue";
import * as Sentry from "@sentry/vue";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import backendCall from "../../services/AxiosService";
import {useToast} from "primevue/usetoast";
import {tempWithinThreshold} from "../../utilities/tempValidation";

const toast = useToast();

import {useGeolocation} from '@vueuse/core'

const {coords, locatedAt, error, resume, pause} = useGeolocation()

watch(coords, (newCoords) => {
  if (newCoords.latitude && newCoords.longitude && newCoords.accuracy) {
    unitCheckData.value.browserCoordinates = {
      latitude: newCoords.latitude,
      longitude: newCoords.longitude,
      accuracy: newCoords.accuracy,
    };
  }
});

interface TemperatureData {
  value: number | undefined;
  unit: 'C' | 'F' | undefined;
  rawInput: string;
}

interface UnitCheckData {
  unitNumber: string;
  returnTemp: TemperatureData;
  billedSetpoint: TemperatureData;
  actualSetpoint: TemperatureData;
  supplyTemp: TemperatureData;
  truckNumber: number | undefined;
  fuelAdded: number | null;
  startFuelLevel: string | null;
  endFuelLevel: string | null;
  alarms: [];
  notes: string;
  eventCode: string | null;
  unitType: string | null;
  browserCoordinates: {},
  onRvr: boolean,
  inDefrost: boolean
}

const visible = defineModel('visible');
const props = defineProps({visible: Boolean, yardCheckId: Number});
const emit = defineEmits(['visible-change', 'refresh-yardcheck']);

const unitNumberValidation = ref();

const temperatureDiscrepancies = ref(new Set<string>());

const invalidFields = ref(new Set<string>());

function getInitialUnitCheckData(): UnitCheckData {
  return {
    unitNumber: '',
    billedSetpoint: {value: null, unit: null, rawInput: ''},
    actualSetpoint: {value: null, unit: null, rawInput: ''},
    returnTemp: {value: null, unit: null, rawInput: ''},
    supplyTemp: {value: null, unit: null, rawInput: ''},
    truckNumber: null,
    fuelAdded: null,
    startFuelLevel: null,
    endFuelLevel: null,
    alarms: [],
    notes: '',
    eventCode: null,
    unitType: null,
    onRvr: true,
    inDefrost: false,
    browserCoordinates: {},
  };
}

const unitCheckData = ref<UnitCheckData>(getInitialUnitCheckData());


const fuelOptions = ref(['',
  'E',
  '1/8',
  '1/4',
  '3/8',
  '5/16',
  '7/16',
  '1/2',
  '9/16',
  '5/8',
  '3/4',
  '13/16',
  '7/8',
  'F']);

const eventCodeOptions = ref(['IG', 'DR', 'AS', 'TA', 'PN', 'SW','RN']);

const unitTypeOptions = ref(['KR1', 'KR2', 'KR4', 'KR7', 'KH7']);


// Initialize as an empty array
const alarmSuggestions = ref<string[]>([]);
const allAlarms = ref<string[]>([]);

// Modify fetchAlarmList to update alarmSuggestions
const fetchAlarmList = async () => {
  try {
    const fetchAlarmListCall = await backendCall.get(`units/all-alarms`);
    allAlarms.value = [...fetchAlarmListCall.data.data];
    // Update alarmSuggestions here
    alarmSuggestions.value = [...allAlarms.value];
    // console.log(allAlarms.value);
  } catch (error) {
    console.error("Error fetching alarm list:", error);
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Failed to fetch alarm list',
      life: 3000
    });
  }
}

const fetchDriverVehicle = async () => {
  try {
    const fetchDriverVehicleCall = await backendCall.get(`user/vehicle-assignment`);
    unitCheckData.value.truckNumber = fetchDriverVehicleCall.data.data?.vehicleAssignment;
    if (!unitCheckData.value.truckNumber) {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to fetch your current vehicle. Are you signed in to your vehicle?',
        life: 10000
      });
    }
  } catch (error) {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Failed to fetch your current vehicle.',
      life: 3000
    });
  }
}

onMounted(async () => {
  await fetchAlarmList();
  await fetchDriverVehicle()
  console.log(coords);
})


const searchAlarms = (event: { query: string }) => {
  const query = event.query.trim().toLowerCase();

  if (query.length === 0) {
    alarmSuggestions.value = [...allAlarms.value];
  } else {
    alarmSuggestions.value = allAlarms.value.filter((alarm) =>
        alarm.alarm_code.toLowerCase().includes(query) || alarm.alarm_name.toLowerCase().includes(query)
    );
  }
};

function validateTemperatureField(field: keyof UnitCheckData): boolean {
  const data = unitCheckData.value[field] as TemperatureData;
  if (!data.rawInput) {
    invalidFields.value.delete(field);
    return true; // Consider empty field as valid
  }

  const match = data.rawInput.match(/^(-?\d+(\.\d+)?)(C|F)$/i);
  if (match) {
    const [, numPart, , unitPart] = match;
    const numValue = parseFloat(numPart);
    const unitValue = unitPart.toUpperCase() as 'C' | 'F';

    unitCheckData.value[field] = {
      value: numValue,
      unit: unitValue,
      rawInput: data.rawInput
    };

    invalidFields.value.delete(field);
    checkTemperatureDiscrepancies();
    return true;
  }

  invalidFields.value.add(field);
  toast.add({
    severity: 'error',
    summary: 'Invalid Temperature',
    detail: `${field} must be a number followed by C or F`,
    life: 5000
  });
  return false;
}

// new function to check temperature discrepancies
function checkTemperatureDiscrepancies() {
  const {billedSetpoint, actualSetpoint, returnTemp} = unitCheckData.value;

  temperatureDiscrepancies.value.clear();

  const SETPOINT_DISCREPANCY_THRESHOLD = 0.5;
  const SETPOINT_DISCREPANCY_THRESHOLD_UNIT = 'C';
  const OUT_OF_RANGE_THRESHOLD = 2.5;
  const OUT_OF_RANGE_THRESHOLD_UNIT = 'C'

  if (billedSetpoint.value !== null && actualSetpoint.value !== null) {
    const withinThreshold = tempWithinThreshold(
        billedSetpoint.value,
        billedSetpoint.unit as 'C' | 'F',
        actualSetpoint.value,
        actualSetpoint.unit as 'C' | 'F',
        SETPOINT_DISCREPANCY_THRESHOLD,
        SETPOINT_DISCREPANCY_THRESHOLD_UNIT
    );

    if (!withinThreshold) {
      temperatureDiscrepancies.value.add('setpoint');
      // toast.add({
      //   severity: 'warn',
      //   summary: 'Setpoint Discrepancy!',
      //   detail: `The actual setpoint is not within ${SETPOINT_DISCREPANCY_THRESHOLD}°${SETPOINT_DISCREPANCY_THRESHOLD_UNIT} of the billed setpoint.`,
      //   life: 5000
      // });
    }
  }

  if (actualSetpoint.value !== null && returnTemp.value !== null) {
    console.log('HIT');
    const withinThreshold = tempWithinThreshold(
        actualSetpoint.value,
        actualSetpoint.unit as 'C' | 'F',
        returnTemp.value,
        returnTemp.unit as 'C' | 'F',
        OUT_OF_RANGE_THRESHOLD,
        OUT_OF_RANGE_THRESHOLD_UNIT
    );

    if (!withinThreshold) {
      temperatureDiscrepancies.value.add('return-temp');
      // toast.add({
      //   severity: 'warn',
      //   summary: 'Unit Out Of Range!',
      //   detail: `The Return Temp is not within ${OUT_OF_RANGE_THRESHOLD}°${OUT_OF_RANGE_THRESHOLD_UNIT} of the Setpoint`,
      //   life: 5000
      // });
    }
  }

}

async function validateUnitNumber(): Promise<void> {
  if (!unitCheckData.value.unitNumber) {
    invalidFields.value.add('unitNumber');
    return;
  }
  // Format the unit number before validation
  const unitNumber = unitCheckData.value.unitNumber;

  try {
    const response = await backendCall.get(`units/lookup?unit_number=${unitNumber}`);
    // console.log(response?.data?.data?.cnApiResponse?.CarKind);
    unitNumberValidation.value = response?.data?.data;
    unitCheckData.value.unitType = null;
    if (response?.data?.data?.dbResponse?.unit_type) {
      unitCheckData.value.unitType = response?.data?.data?.dbResponse?.unit_type;
    } else if (response?.data?.data?.cnApiResponse?.CarKind) {
      unitCheckData.value.unitType = response?.data?.data?.cnApiResponse.CarKind;
    }
    if (response.data.data.isValid) {
      invalidFields.value.delete('unitNumber');
    } else {
      invalidFields.value.add('unitNumber');
      // Uncomment to add toast warning as well.
      // toast.add({
      //   severity: 'warn',
      //   summary: 'Possible Invalid Unit Number!',
      //   detail: 'The entered unit number may not be valid! Please verify the unit number.',
      //   life: 10000
      // });
    }
  } catch (error) {
    console.error('Error validating unit number:', error);
    toast.add({
      severity: 'error',
      summary: 'Validation Error',
      detail: 'An error occurred while validating the unit number.',
      life: 3000
    });
    invalidFields.value.add('unitNumber');
  }
}

const isSubmitting = ref(false)

const submitNewYardCheckUnit = async () => {
  if (isSubmitting.value) return // Check if previous form submission is in progress, if so return early.

  isSubmitting.value = true

  try {
    // Validate all fields
    const temperatureFields = ['billedSetpoint', 'actualSetpoint', 'returnTemp', 'supplyTemp'] as const;
    for (const field of temperatureFields) {
      if (!validateTemperatureField(field)) {
        isSubmitting.value = false;
        return;
      }
    }

    // Check if at least one of returnTemp or supplyTemp is provided
    const hasReturnTemp = unitCheckData.value.returnTemp.value !== undefined && unitCheckData.value.returnTemp.unit !== undefined;
    const hasSupplyTemp = unitCheckData.value.supplyTemp.value !== undefined && unitCheckData.value.supplyTemp.unit !== undefined;

    if (!hasReturnTemp && !hasSupplyTemp) {
      toast.add({
        severity: 'error',
        summary: 'Missing Information',
        detail: 'At least one of Return Temperature or Supply Temperature is required.',
        life: 5000
      });
      return; // Stop submission if both are missing
    }

    // At this point, all fields are valid and at least one of returnTemp or supplyTemp is provided
    const dataToSubmit = {
      ...unitCheckData.value,
      // Ensure we're only sending number values for temperatures
      billedSetpoint: unitCheckData.value.billedSetpoint.value !== null ? {
        value: unitCheckData.value.billedSetpoint.value,
        unit: unitCheckData.value.billedSetpoint.unit
      } : undefined,

      actualSetpoint: unitCheckData.value.actualSetpoint.value !== null ? {
        value: unitCheckData.value.actualSetpoint.value,
        unit: unitCheckData.value.actualSetpoint.unit
      } : undefined,


      returnTemp: unitCheckData.value.returnTemp.value !== null ? {
        value: unitCheckData.value.returnTemp.value,
        unit: unitCheckData.value.returnTemp.unit
      } : undefined,

      supplyTemp: unitCheckData.value.supplyTemp.value !== null ? {
        value: unitCheckData.value.supplyTemp.value,
        unit: unitCheckData.value.supplyTemp.unit
      } : undefined,

      browserCoordinates: unitCheckData.value.browserCoordinates,
    };

    // Check if unitNumber and actualSetpoint are provided. Not allowing blank unit numbers or missing setpoints.
    if (!dataToSubmit?.unitNumber) {
      toast.add({
        severity: 'error',
        summary: 'Missing Information',
        detail: 'Unit Number is Required!',
        life: 5000
      });
      return;
    }

    if (dataToSubmit?.actualSetpoint?.value == null || dataToSubmit?.actualSetpoint?.unit == null) {
      toast.add({
        severity: 'error',
        summary: 'Missing Information',
        detail: 'Actual Setpoint is Required!',
        life: 5000
      });
      return;
    }

    if (!dataToSubmit?.unitType) {
      toast.add({
        severity: 'error',
        summary: 'Missing Information',
        detail: 'Unit Type is Required!',
        life: 5000
      });
      return;
    }

    const submitYardCheckUnitCall = await backendCall.post(`yard-check/${props.yardCheckId}/unit`, dataToSubmit);
    if (!submitYardCheckUnitCall) {
      throw new Error('API Error')
    }

    // Reset the form
    unitCheckData.value = getInitialUnitCheckData();

    emit("refresh-yardcheck");
    visible.value = false;
  } catch (e) {
    Sentry.captureException(e);
    toast.add({
      severity: 'error',
      summary: 'Could not submit unit check!',
      detail: 'An error occurred while submitting the unit check!',
      life: 5000
    });
    throw e;
  } finally {
    isSubmitting.value = false;
  }
}

</script>

<template>
  <!--v-model:visible="visible"-->
  <Dialog class="w-full max-w-lg md:w-2/3" v-model:visible="visible" appendTo="body" modal
           header="New Unit Check" :draggable="false" :resizable="false" :closable="true">
    <form class="flex flex-col gap-4 mt-4">
      <div class="flex gap-4">
        <div class="w-full">
          <label for="unit-number" class="block mb-1 text-color text-base">Unit Number</label>
          <div class="flex flex-col gap-2">
            <InputGroup>
              <InputGroupAddon>
                <i class="pi pi-hashtag mb-2"></i>
              </InputGroupAddon>
              <InputText name="unit-number" autofocus v-model.trim="unitCheckData.unitNumber"
                         :invalid="!unitCheckData.unitNumber" @blur="validateUnitNumber" type="text" class="w-full"
                         id="unit-number"/>
            </InputGroup>
            <inline-message v-if="unitCheckData.unitNumber && invalidFields.has('unitNumber')" severity="warn">Unit
              Number May Be Invalid! &nbsp; Please Verify.
            </inline-message>
          </div>
        </div>
      </div>
      <div class="flex gap-4">
        <div class="w-full">
          <label for="event_code" class="block mb-1 text-color text-base">Event Code</label>
          <InputGroup>
            <InputGroupAddon>
              EV
            </InputGroupAddon>
            <Select name="event_code" type="text" v-model="unitCheckData.eventCode" class="w-full"
                    id="event-code"
                    :options="eventCodeOptions"/>
          </InputGroup>
        </div>
        <div class="w-full">
          <label for="unit-type" class="block mb-1 text-color text-base">Unit Type</label>

          <Select name="unit_type" :disabled="unitNumberValidation?.dbResponse?.unit_type" type="text"
                  v-model="unitCheckData.unitType" class="w-full"
                  id="unit-type"
                  :options="unitTypeOptions"/>
        </div>
        <div class="w-full text-center">
          <label for="rvr_switch" class="block text-color text-base mb-4">On RVR</label>
          <ToggleSwitch v-model="unitCheckData.onRvr" id="rvr_switch"></ToggleSwitch>
          <!--          <Checkbox v-model="unitCheckData.onRvr" :binary="true" id="rvr_switch"></Checkbox>-->
        </div>
      </div>
      <div class="flex gap-4">
        <div class="w-full">
          <label for="billed-setpoint" class="block mb-1 mt-2 text-color text-base">Billed Setpoint</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="fa-solid fa-temperature-three-quarters"></i>
            </InputGroupAddon>
            <InputText name="billed_setpoint" type="text" v-model="unitCheckData.billedSetpoint.rawInput" class="w-full"
                       id="billed-setpoint" :invalid="invalidFields.has('billedSetpoint')"
                       @blur="validateTemperatureField('billedSetpoint')"/>
          </InputGroup>
          <inline-message v-if="temperatureDiscrepancies.has('setpoint')" severity="warn">Setpoint Discrepancy!
          </inline-message>
        </div>
        <div class="w-full">
          <label for="actual-setpoint" class="block mb-1 mt-2 text-color text-base">Actual Setpoint</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="fa-solid fa-temperature-three-quarters"></i>
            </InputGroupAddon>
            <InputText name="actual_setpoint" type="text" v-model="unitCheckData.actualSetpoint.rawInput" class="w-full"
                       id="actual-setpoint" :invalid="invalidFields.has('actualSetpoint')"
                       @blur="validateTemperatureField('actualSetpoint')"/>
          </InputGroup>
          <inline-message v-if="temperatureDiscrepancies.has('setpoint')" severity="warn">Setpoint Discrepancy!
          </inline-message>
        </div>
      </div>
      <div class="flex gap-4">
        <div class="w-full">
          <label for="return-temp" class="block mb-1 mt-2 text-color text-base">Return Temp</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="fa-solid fa-temperature-three-quarters"></i>
            </InputGroupAddon>
            <InputText name="return_temp" type="text" v-model="unitCheckData.returnTemp.rawInput" class="w-full"
                       id="return-temp"
                       :invalid="invalidFields.has('returnTemp')" @blur="validateTemperatureField('returnTemp')"/>
          </InputGroup>
          <inline-message v-if="temperatureDiscrepancies.has('return-temp')" severity="warn">Out Of Range!
          </inline-message>

        </div>
        <div class="w-full">
          <label for="supply-temp" class="block mb-1 mt-2 text-color text-base">Supply Temp</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="fa-solid fa-temperature-three-quarters"></i>
            </InputGroupAddon>
            <InputText name="supply_temp" type="text" v-model="unitCheckData.supplyTemp.rawInput" class="w-full"
                       id="supply-temp"
                       :invalid="invalidFields.has('supplyTemp')" @blur="validateTemperatureField('supplyTemp')"/>
          </InputGroup>
        </div>
        <div class="w-full text-center">
          <label for="rvr_switch" class="block mt-2 text-color text-base mb-4">Defrost</label>
          <ToggleSwitch v-model="unitCheckData.inDefrost" id="rvr_switch"></ToggleSwitch>
          <!--          <Checkbox v-model="unitCheckData.onRvr" :binary="true" id="rvr_switch"></Checkbox>-->
        </div>
      </div>
      <div class="flex gap-4">
        <div class="w-full">
          <label for="start-fuel" class="block mb-1 mt-2 text-color text-base">Fuel Level</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-gauge text-lg"></i>
            </InputGroupAddon>
            <Select name="start_fuel" type="text" v-model="unitCheckData.startFuelLevel" class="w-full"
                    id="start-fuel"
                    :options="fuelOptions"/>
          </InputGroup>
        </div>

        <div class="w-full">
          <label for="fuel-added" class="block mb-1 mt-2 text-color text-base">Fuel Added</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-gauge text-lg"></i>
            </InputGroupAddon>
            <InputNumber v-model="unitCheckData.fuelAdded" title="Fuel Added"></InputNumber>
            <InputGroupAddon>L</InputGroupAddon>
          </InputGroup>
        </div>

      </div>
      <div v-if="unitCheckData.fuelAdded" class="flex justify-center gap-4">
        <div class="w-full">
          <label for="end-fuel" class="block mb-1 mt-2 text-color text-base">End Fuel</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-gauge text-lg"></i>
            </InputGroupAddon>
            <Select name="end-fuel" type="text" v-model="unitCheckData.endFuelLevel" class="w-full" id="end-fuel"
                    :options="fuelOptions.toReversed()"/>
          </InputGroup>
        </div>
        <div class="w-full">
          <label for="truck_number" class="block mb-1 mt-2 text-color text-base">Truck #</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-truck text-lg"></i>
            </InputGroupAddon>
            <InputNumber v-model="unitCheckData.truckNumber" id="truck_number" title="Truck #"></InputNumber>
          </InputGroup>
        </div>
      </div>

      <div>
        <label for="alarms" class="block mb-1 mt-2 text-color text-base">Alarms</label>
        <InputGroup>
          <InputGroupAddon>
            <i class="pi pi-exclamation-triangle mb-2"></i>
          </InputGroupAddon>
          <AutoComplete :multiple="true" v-model="unitCheckData.alarms" :suggestions="alarmSuggestions"
                        :option-label="(alarmSuggestions) => {return alarmSuggestions.manufacturer + ' - ' + alarmSuggestions.alarm_code + ' - ' + alarmSuggestions.alarm_name}"
                        :force-selection="false" @complete="searchAlarms">
            <template #option="slotProps">
              {{ slotProps.option.manufacturer }} - {{ slotProps.option.alarm_code }} - {{
                slotProps.option.alarm_name
              }}
            </template>
          </AutoComplete>
        </InputGroup>
      </div>
      <div>
        <label for="notes" class="block mb-1 mt-2 text-color text-base">Notes</label>
        <InputGroup>
          <InputGroupAddon>
            <i class="pi pi-file mb-2"></i>
          </InputGroupAddon>
          <Textarea name="notes" v-model="unitCheckData.notes" class="w-full" id="notes"/>
        </InputGroup>
      </div>
    </form>
    <template #footer>
      <div class="flex gap-4 justify-end border-t border-surface pt-8">
        <Button label="Cancel" @click="visible = false" class="p-button-text"></Button>
        <Button label="Submit" @click=submitNewYardCheckUnit :disabled="isSubmitting" class="p-button-rounded"></Button>
      </div>
    </template>
  </Dialog>

</template>

<style scoped>

</style>