import {defineStore} from 'pinia';
import backendCall from "../services/AxiosService";


export const useRequestsStore = defineStore({
    id: 'requests', state: () => ({
        requests: [],
        requestCount: null
    }), actions: {
        fetchRequests() {
            backendCall.get('requests')
                .then(response => {
                    const requestResponse = response.data.data;

                        this.requests = requestResponse;
                        this.requestCount = requestResponse.length;

                })
                .catch(error => {
                    console.error('There was a problem with the axios operation:', error);
                });
        }
    },

});
