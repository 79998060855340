<script setup>
import backendCall from "../../services/AxiosService";
import {onMounted, ref} from "vue";

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import TrainTimeStatistics from "./TrainTimeStatistics.vue";


const props = defineProps({
  trainid: String
})

const trainId = ref();
const trainArrivalTime = ref();
const trainTime = ref();
const totalTime = ref();
const totalWaitTime = ref();
const workersOnTrainCount = ref();
const workersOnTrain = ref();
const totalElectrics = ref();
const totalDomestics = ref();
const totalGensets = ref();
const totalRequests = ref();

const getTrainTime = async () => {

  await backendCall.get(`${import.meta.env.VITE_API_BASE_URL}trains/${props.trainid}/labour-time?train_id=${props.trainid}`)
      .then(response => {
        return response;
      })
      .then(data => {
        trainId.value = data.data.train_id;
        totalElectrics.value = data?.data?.electric_count;
        totalRequests.value = data?.data?.request_count;
        totalGensets.value = data?.data?.genset_count;
        totalDomestics.value = data.data?.domestic_count;
        trainTime.value = data.data.billing_time_records;
      totalTime.value = data.data.total_time;
      totalWaitTime.value = data.data.total_wait_time;
      workersOnTrainCount.value = data.data.workers_on_train_count;
      workersOnTrain.value = data.data.workers_on_train;
      trainArrivalTime.value = data.data.train_arrival_time;
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });

}

onMounted(() => {
  getTrainTime()
})
</script>

<template>
  <div class="bg-surface-0 dark:bg-surface-950 px-6 py-0 md:px-12 lg:px-20">
    <TrainTimeStatistics :train-id="trainId" :total-time="totalTime" :total-wait-time="totalWaitTime" :workersOnTrain="workersOnTrain" :workers-on-train-count="workersOnTrainCount" :train-arrival-time="trainArrivalTime" :total-electrics="parseInt(totalElectrics)" :total-gensets="parseInt(totalGensets)" :total-requests="parseInt(totalRequests)" :total-domestics="parseInt(totalDomestics)" ></TrainTimeStatistics>
<!--    <ul class="list-none p-0 m-0 flex items-center font-medium mb-4">-->
<!--      <li>-->
<!--        <a class="text-surface-500 dark:text-surface-300 no-underline leading-normal cursor-pointer">Application</a>-->
<!--      </li>-->
<!--      <li class="px-2">-->
<!--        <i class="pi pi-angle-right text-surface-500 dark:text-surface-300 leading-normal"></i>-->
<!--      </li>-->
<!--      <li>-->
<!--        <span class="text-surface-900 dark:text-surface-0 leading-normal">Analytics</span>-->
<!--      </li>-->
<!--    </ul>-->
    <div class="flex items-start flex-col lg:justify-center lg:flex-row">
      <div>
        <div class="font-medium text-3xl text-surface-900 dark:text-surface-0">Billing Time Records</div>
<!--        <div class="flex items-center text-surface-700 dark:text-surface-100 flex-wrap">-->
<!--          <div class="mr-8 flex items-center mt-4">-->
<!--            <i class="pi pi-users mr-2"></i>-->
<!--            <span>332 Active Users</span>-->
<!--          </div>-->
<!--          <div class="mr-8 flex items-center mt-4">-->
<!--            <i class="pi pi-globe mr-2"></i>-->
<!--            <span>9402 Sessions</span>-->
<!--          </div>-->
<!--          <div class="flex items-center mt-4">-->
<!--            <i class="pi pi-clock mr-2"></i>-->
<!--            <span>2.32m Avg. Duration</span>-->
<!--          </div>-->
<!--        </div>-->
      </div>
<!--      <div class="mt-4 lg:mt-0">-->
<!--        <Button label="Add" class="p-button-outlined mr-2" icon="pi pi-user-plus"></Button>-->
<!--        <Button label="Save" icon="pi pi-check"></Button>-->
<!--      </div>-->
    </div>
    <DataTable :value="trainTime" tableStyle="min-width: 50rem">
      <Column field="first_name" header="First Name"></Column>
      <Column field="last_name" header="Last Name"></Column>
      <Column field="duration" header="Duration (hh:mm:ss)"></Column>
      <Column header="Start Time">
        <template #body="slotProps">
          {{new Date(slotProps.data.start_time).toLocaleString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hourCycle: 'h23'
        })
            }}
        </template>
      </Column>
      <Column header="End Time">
        <template #body="slotProps">
          {{slotProps?.data?.end_time ? new Date(slotProps.data.end_time).toLocaleString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hourCycle: 'h23'
        }) : null
          }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<style scoped>

</style>