<template>
  <div class="card flex flex-wrap justify-center md:w-full">

    <Dialog :visible="props.submitOutcomeModalVisible" @update:visible="$emit('visible-change')" modal header="Submit Train Outcome" :breakpoints="{ '1199px': '50vw', '575px': '90vw' }">
      <template #header>
        <div class="inline-flex items-center justify-center gap-2">
          <span class="font-bold whitespace-nowrap">Submit Train Outcome</span>
        </div>
      </template>
<!--      <span class="p-text-secondary block mb-8">Update your information.</span>-->
<!--      <div class="flex items-center gap-4 mb-4">-->
<!--        <label for="username" class="font-semibold w-24">Username</label>-->
<!--        <InputText id="username" class="flex-auto" autocomplete="off" />-->
<!--      </div>-->
      <div class="flex items-center gap-4 mb-2">
        <label for="email" class="font-semibold w-24">Notes</label>
        <Textarea id="email" v-model="outcomeNotes" class="flex-auto" autocomplete="off" > </Textarea>
      </div>
      <template #footer>
        <div class="flex flex-wrap gap-2">
        <Button label="Cancel" text severity="secondary" @click.prevent="$emit('visible-change')" autofocus />
        <Button label="Denied" outlined severity="warning" @click="$emit('trainOutcomeSubmit', {train: props.trainid, result: 'denied', notes: outcomeNotes})" autofocus />
        <Button label="Skipped" outlined severity="info" @click="$emit('trainOutcomeSubmit', {train: props.trainid, result: 'skipped', notes: outcomeNotes})" autofocus />
        <Button label="Missed" outlined severity="danger" @click="$emit('trainOutcomeSubmit', {train: props.trainid, result: 'missed', notes: outcomeNotes})" autofocus />
        <Button v-if="!omitComplete" label="Completed" outlined severity="success" @click="$emit('trainOutcomeSubmit', {train: props.trainid, result: 'completed', notes: outcomeNotes})" autofocus />
        </div>
      </template>
    </Dialog>
  </div>

</template>

<script setup>
import {onMounted, ref} from 'vue';

const omitCompleteButton = ref(false);

const outcomeNotes = ref(null);

const props = defineProps({
  submitOutcomeModalVisible: Boolean,
  trainid: String,
  omitComplete: Boolean,
})

onMounted(() => {
  omitCompleteButton.value = props.omitComplete || false;
})

const emits = defineEmits(["visible-change", 'closeOutcomeModal','trainOutcomeSubmit']);

</script>
