<script setup lang="ts">
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import {onMounted, ref} from 'vue';
import backendCall from "../../services/AxiosService";

const props = defineProps({trainId: Number})

const visible = defineModel("visible");
const workerOptions = ref([

]);
const selectedWorkers = ref([]);

const getUsers = async () => {
  const usersCall = await backendCall.get('dispatch/workers');
  workerOptions.value = usersCall.data.data.users;
}

const isSubmitting = ref(false);
const submitAssignedWorkers = async () => {
  try {
    isSubmitting.value = true;
  await backendCall.post(`trains/${props.trainId}/assign-workers`, {workers: selectedWorkers.value})
    } catch (e) {
    toast.add({ severity: 'error', summary: 'Failed', detail: `Failed to assign workers to train!`, life: 5000 });

  } finally {
    isSubmitting.value = false;
  }
}

onMounted(() => {
  getUsers();
})

</script>


<template>
  <Dialog v-model:visible="visible" :modal="true" :closable="false" :show-header="false" :breakpoints="{ '960px': '75vw', '640px': '96vw' }" :style="{ width: '700px' }">
      <section class="flex flex-col w-full mt-6">
        <div class="flex w-full justify-between mb-6">
          <span class="w-12 h-12 rounded-full flex justify-center items-center bg-highlight"><i class="pi pi-users text-highlight-contrast !text-2xl" /></span>
          <Button type="button" icon="pi pi-times" class="self-start" rounded text severity="secondary" @click="visible = false" />
        </div>
        <p class="font-semibold text-xl mt-0 mb-2 text-surface-900 dark:text-surface-0">Assign Workers</p>
        <p class="font-normal text-base mt-0 mb-4 text-surface-600 dark:text-surface-200">Assign workers to train.</p>
        <MultiSelect v-model="selectedWorkers" :options="workerOptions" optionLabel="first_name" filter placeholder="Select Workers"
                     :maxSelectedLabels="3" class="w-full rounded-lg">
          <template #option="option">
            {{option.option.first_name + ' ' + option.option.last_name}}
          </template>
        </MultiSelect>
      </section>
      <template #footer>
        <div class="pt-4 flex w-full gap-4">
          <Button label="Cancel" class="grow" text @click="visible = false" />
          <Button label="Submit" class="grow" :disabled="isSubmitting" @click="submitAssignedWorkers"/>
        </div>
      </template>
    </Dialog>
</template>
