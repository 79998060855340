<template>

  <div class="h-full w-screen bg-surface-50 dark:bg-surface-950 relative flex overflow-hidden">
    <!-- Sidebar -->
    <div id="app-sidebar-1" ref="sidebar"
         :class="[
           'bg-white dark:bg-black transition-all duration-300 h-full shrink-0 z-50 select-none overflow-y-auto',
           {'w-0 lg:w-0': !sidebarVisible},
           {'w-280px': sidebarVisible},
           'fixed lg:relative left-0 top-0'
         ]">
      <div class="flex flex-col border-2 bg-white dark:bg-black border-solid rounded-xl h-full overflow-hidden">
          <div class="flex items-center justify-between px-8 shrink-0" style="height:60px">
            <img src="/logo.png" alt="Image" height="50"/>
            <a v-ripple class="cursor-pointer lg:hidden block text-surface-700 dark:text-surface-300 mr-4 mt-1 p-ripple"
               @click.stop="toggleSidebar">
              <!--             v-styleclass="{ selector: '#app-sidebar-1', enterFromClass: 'hidden', enterActiveClass: 'my-fadein', leaveToClass: 'hidden', leaveActiveClass: 'my-fadeout', hideOnOutsideClick: true }"-->

              <i class="pi pi-chevron-left lg:hidden text-2xl"></i>
            </a>
          </div>
          <div class="overflow-y-auto">
            <ul class="list-none p-4 m-0 mt-4">

                  <!--                <li>-->
                  <!--                  <router-link to="/trains" v-ripple-->
                  <!--                               class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-100 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">-->
                  <!--                    <span class="material-icons mr-2">train</span>-->
                  <!--                    <span class="font-medium">Trains</span>-->
                  <!--                    <span-->
                  <!--                        class="inline-flex items-center justify-center ml-auto bg-primary-500 text-surface-0 dark:text-surface-900 rounded-full"-->
                  <!--                        style="min-width: 1.5rem; height: 1.5rem">{{ trainsStore.trainCount }}</span>-->
                  <!--                  </router-link>-->
                  <!--                </li>-->
                  <li>
                    <a v-ripple
                       class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple"
                       v-styleclass="{ selector: '@next', enterFromClass: 'hidden', enterActiveClass: 'my-fadein', leaveActiveClass: 'my-fadeout', leaveToClass: 'hidden' }">
                      <span class="material-icons mr-2">train</span>
                      <span class="font-medium">Trains</span>
                      <i class="pi pi-chevron-down ml-auto"></i>
                      <Badge class="ml-3" :value="trainsStore.trainCount"/>
                    </a>
                    <ul class="list-none py-0 pl-4 pr-0 m-0 hidden overflow-y-hidden transition-all duration-[400ms] ease-in-out">
                      <li>
                        <router-link to="/trains" v-ripple
                                     class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                          <i class="pi pi-list mr-2"></i>

                          <span class="font-medium">Current</span>
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/trains/history" v-ripple
                                     class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                          <i class="pi pi-history mr-2"></i>
                          <span class="font-medium">History</span>
                        </router-link>
                      </li>
                    </ul>
                  </li>

                  <li>

                  </li>
                  <li>
                    <a v-ripple
                       class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple"
                       v-styleclass="{ selector: '@next', enterFromClass: 'hidden', enterActiveClass: 'my-fadein', leaveActiveClass: 'my-fadeout', leaveToClass: 'hidden' }">
                      <i class="pi pi-exclamation-circle mr-2"></i>
                      <span class="font-medium">Requests</span>
                      <i class="pi pi-chevron-down ml-auto"></i>
                      <Badge class="ml-3" :value="requestStore.requestCount"/>
                    </a>
                    <ul class="list-none py-0 pl-4 pr-0 m-0 hidden overflow-y-hidden transition-all duration-[400ms] ease-in-out">
                      <li>
                        <router-link to="/requests" v-ripple
                                     class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                          <i class="pi pi-list mr-2"></i>

                          <span class="font-medium">Current</span>
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/requests" v-ripple
                                     class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                          <i class="pi pi-history mr-2"></i>
                          <span class="font-medium">History</span>
                        </router-link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <router-link to="/yard-check" v-ripple
                                 class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                      <i class="pi pi-clipboard mr-2"></i>
                      <span class="font-medium">Yard Check</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/clipons" v-ripple
                                 class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                      <i class="fa-solid fa-plug mr-2"></i>
                      <span class="font-medium">Clip-Ons</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/fuel" v-ripple
                                 class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                      <i class="fa-solid fa-gas-pump mr-2"></i>
                      <span class="font-medium">Refueling</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/unitquery" v-ripple
                                 class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                      <i class="fa-solid fa-magnifying-glass mr-2"></i>
                      <span class="font-medium">Unit Lookup</span>
                    </router-link>
                  </li>
                  <li>
                    <a v-ripple
                       class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple"
                       v-styleclass="{ selector: '@next', enterFromClass: 'hidden', enterActiveClass: 'my-fadein', leaveActiveClass: 'my-fadeout', leaveToClass: 'hidden' }">
                      <i class="pi pi-user mr-2"></i>
                      <span class="font-medium">Staffing</span>
                      <i class="pi pi-chevron-down ml-auto"></i>
                    </a>
                    <ul class="list-none py-0 pl-4 pr-0 m-0 hidden overflow-y-hidden transition-all duration-[400ms] ease-in-out">
                      <li>
                        <router-link to="/staffing/whos-in" v-ripple
                                     class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                          <i class="pi pi-list mr-2"></i>

                          <span class="font-medium">Who's In</span>
                        </router-link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <router-link to="/fleet/summary" v-ripple
                                 class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                      <i class="pi pi-truck mr-2"></i>
                      <span class="font-medium">Fleet</span>
                    </router-link>
                  </li>
                  <li v-if="authStore?.user?.roles.includes(3500)">
                    <router-link to="/billing/jobs" v-ripple
                                 class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                      <!--                    <i class="fa-solid fa-file-lines mr-2"></i>-->
                      <i class="pi pi-dollar mr-2"></i>
                      <span class="font-medium">Billing</span>
                    </router-link>
                  </li>
                  <li v-if="authStore?.user?.roles.includes(1000)">
                    <router-link to="/admin" v-ripple
                                 class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                      <i class="pi pi-cog mr-2"></i>
                      <span class="font-medium">Admin</span>
                    </router-link>
                  </li>
                  <li>
                    <a v-ripple
                       class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple"
                       v-styleclass="{ selector: '@next', enterFromClass: 'hidden', enterActiveClass: 'my-fadein', leaveActiveClass: 'my-fadeout', leaveToClass: 'hidden' }">
                      <i class="pi pi-chart-line mr-2"></i>
                      <span class="font-medium">Reports</span>
                      <i class="pi pi-chevron-down ml-auto"></i>
                    </a>
                    <ul class="list-none py-0 pl-4 pr-0 m-0 hidden overflow-y-hidden transition-all duration-[400ms] ease-in-out">
                      <li v-if="false">
                        <a v-ripple
                           class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple"
                           v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup' }">
                          <i class="pi pi-chart-line mr-2"></i>
                          <span class="font-medium">Report 1</span>
                          <i class="pi pi-chevron-down ml-auto"></i>
                        </a>
                        <ul class="list-none py-0 pl-4 pr-0 m-0 hidden overflow-y-hidden transition-all duration-[400ms] ease-in-out">
                          <li>
                            <a v-ripple
                               class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                              <i class="pi pi-table mr-2"></i>
                              <span class="font-medium">View</span>
                            </a>
                          </li>
                          <li>
                            <a v-ripple
                               class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                              <i class="pi pi-search mr-2"></i>
                              <span class="font-medium">Search</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li v-if="authStore?.user?.roles.includes(1000)">
                        <router-link to="/reports/statistics" v-ripple
                                     class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                          <i class="pi pi-chart-line mr-2"></i>
                          <span class="font-medium">Statistics</span>
                        </router-link>
                  </li>
                  <li v-if="false">
                    <a v-ripple
                       class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                      <i class="pi pi-users mr-2"></i>
                      <span class="font-medium">Team</span>
                    </a>
                  </li>
                  <li v-if="false">
                    <a v-ripple
                       class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                      <i class="pi pi-comments mr-2"></i>
                      <span class="font-medium">Messages</span>
                      <span
                          class="inline-flex items-center justify-center ml-auto bg-blue-500 text-surface-0 dark:text-surface-900 rounded-full"
                          style="min-width: 1.5rem; height: 1.5rem">3</span>
                    </a>
                  </li>
                  <li v-if="false">
                    <router-link :to="{name: 'calendarComponent'}" v-ripple
                                 class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                      <i class="pi pi-calendar mr-2"></i>
                      <span class="font-medium">Calendar</span>
                    </router-link>
                  </li>
                  <li v-if="false">
                    <a v-ripple
                       class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                      <i class="pi pi-cog mr-2"></i>
                      <span class="font-medium">Settings</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul v-if="false" class="list-none p-4 m-0">
              <li>
                <div v-ripple
                     class="p-4 flex items-center justify-between text-surface-600 dark:text-surface-200 cursor-pointer p-ripple"
                     v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup' }">
                  <span class="font-medium">APPLICATION</span>
                  <i class="pi pi-chevron-down"></i>
                </div>
                <ul class="list-none p-0 m-0 overflow-hidden">
                  <li>
                    <a v-ripple
                       class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                      <i class="pi pi-folder mr-2"></i>
                      <span class="font-medium">Projects</span>
                    </a>
                  </li>
                  <li>
                    <a v-ripple
                       class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                      <i class="pi pi-chart-bar mr-2"></i>
                      <span class="font-medium">Performance</span>
                    </a>
                  </li>
                  <li>
                    <a v-ripple
                       class="flex items-center cursor-pointer p-4 rounded-border text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors p-ripple">
                      <i class="pi pi-cog mr-2"></i>
                      <span class="font-medium">Settings</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div class="p-2 mt-auto border-t border-surface">
            <ul class="list-none p-2 m-0 hidden origin-bottom animate-duration-150">
              <li>
                <router-link :to="{name: 'MyTimes'}" v-ripple
                             class="flex items-center cursor-pointer p-4 text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 rounded-border transition-colors duration-150 p-ripple">
                  <i class="pi pi-clock mr-2"></i>
                  <span class="font-medium">My Times</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'UserSubscriptions'}" v-ripple
                             class="flex items-center cursor-pointer p-4 text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 rounded-border transition-colors duration-150 p-ripple">
                  <i class="pi pi-bell mr-2"></i>
                  <span class="font-medium">My Subscriptions</span>
                </router-link>
              </li>
              <li>
                <router-link to="/user/profile" v-ripple
                             class="flex items-center cursor-pointer p-4 text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 rounded-border transition-colors duration-150 p-ripple">
                  <i class="pi pi-user mr-2"></i>
                  <span class="font-medium">Profile</span>
                </router-link>
              </li>
              <li>
                <a v-ripple
                   class="flex items-center cursor-pointer p-4 text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 rounded-border transition-colors duration-150 p-ripple">
                  <i class="pi pi-cog mr-2"></i>
                  <span class="font-medium">Settings</span>
                </a>
              </li>
              <li>
                <a v-ripple
                   class="flex items-center cursor-pointer p-4 text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 rounded-border transition-colors duration-150 p-ripple"
                   @click="logout">
                  <i class="pi pi-sign-out mr-2"></i>
                  <span class="font-medium">Sign Out</span>
                </a>
              </li>
            </ul>
            <div v-ripple
                 class="p-4 flex items-center cursor-pointer text-surface-700 dark:text-surface-300 hover:bg-surface-100 dark:hover:bg-surface-700 rounded-border transition-colors duration-150 p-ripple"
                 v-styleclass="{ selector: '@prev', enterFromClass: 'hidden', enterActiveClass: 'my-fadein', leaveActiveClass: 'my-fadeout', leaveToClass: 'hidden' }">
              <Avatar :label="`${authStore?.user?.firstName[0]}${authStore?.user?.lastName[0]}`" class="mr-2"
                      style="background-color: #dee9fc; color: #1a2551" shape="circle"/>
              <span class="font-medium">{{ authStore?.user?.firstName }} {{ authStore?.user?.lastName }}</span>
              <i class="pi pi-chevron-up ml-auto"></i>
            </div>
          </div>

      </div>
    </div>

    <!-- Main content area including top bar -->
    <div class="flex flex-col h-full w-full transition-all duration-300 overflow-hidden">
      <!-- Top bar -->
      <div id="top-bar"
           class="flex justify-between items-center mx-2 bg-surface-0 dark:bg-surface-900 border border-surface rounded-xl relative lg:static transition-all duration-300 px-4"
           style="height:60px">
        <div class="flex">
          <a v-ripple class="cursor-pointer block text-surface-700 dark:text-surface-300 mr-4 mt-1 p-ripple"
             @click="toggleSidebar">
            <i class="pi pi-bars text-2xl"></i>
          </a>
        </div>
        <span class="flex mr-2">
          <i class="pi pi-sun mr-1 mt-1"></i>
          <ToggleSwitch class="flex p-3 lg:px-3 py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer
                      transition-duration-150 transition-colors p-ripple" id="darkmode-switch"
                        v-model="isDarkMode"></ToggleSwitch>
          <i class="pi pi-moon ml-1 mt-1"></i>
        </span>
        <Banner v-if="bannerContent" :banner-description="bannerContent"/>
      </div>

      <!-- Main content -->
      <div class="flex-1 overflow-hidden">
        <div class="h-full p-0 lg:p-6 flex flex-col">
          <div
              class="flex-1 border-surface rounded-border w-full bg-surface-0 dark:bg-black dark:bg-surface-950 overflow-y-auto">
            <RouterView></RouterView>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </div>


</template>

<script setup>

import {onMounted, ref, onUnmounted} from "vue";
import {useAuthStore} from "../stores/authStore";
import {useTrainsStore} from "../stores/trainsStore";
import {useRequestsStore} from "../stores/requestStore";
import { useReferenceStore } from "../stores/referenceStore";
import Avatar from 'primevue/avatar';
import Banner from "./Banner.vue";
import {createThemeHandler} from '../utilities/themeHandler'


const sidebar = ref();
const authStore = useAuthStore();
const { isDarkMode } = createThemeHandler(authStore)
const trainsStore = useTrainsStore();
const requestStore = useRequestsStore();
const referenceStore = useReferenceStore();
const bannerContent = ref('');

referenceStore.fetchReferenceData()
trainsStore.fetchTrains();
requestStore.fetchRequests();


const sidebarVisible = ref(window.innerWidth >= 1024); // 1024px is the default 'lg' breakpoint in Tailwind

function handleThemeChange(value) {
  isDarkMode.value = value
  applySavedTheme(authStore)
}

const checkScreenSize = () => {
  if (window.innerWidth >= 1024) {
    sidebarVisible.value = true;
  }
};

const handleClickOutside = (event) => {
  if (window.innerWidth < 1024 && sidebar.value && !sidebar.value.contains(event.target) && sidebarVisible.value) {
    sidebarVisible.value = false;
  }
};

onMounted(async () => {
  window.addEventListener('resize', checkScreenSize);
  document.addEventListener('click', handleClickOutside);
  // authStore.setUser(JSON.parse(localStorage.getItem('user'))); // this has been setting user localstorage item to null string.
});

const getCountsFromServer = async () => {
  trainsStore.fetchTrains();
  requestStore.fetchRequests()
}

const updateBadgesInterval = setInterval(getCountsFromServer, 10000
);

onUnmounted(
    () => {
      window.removeEventListener('resize', checkScreenSize);
      document.removeEventListener('click', handleClickOutside);
      clearInterval(updateBadgesInterval);
    }
)


const toggleSidebar = () => {
  event.stopPropagation();
  sidebarVisible.value = !sidebarVisible.value;
};

const logout = () => {
  authStore.logout();
}


</script>

<style scoped>
.w-280px {
  width: 280px;
}

a {
  text-decoration: none;
}

@keyframes my-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes my-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.my-hidden {
  display: none;
}

.my-fadein {
  animation: my-fadein 150ms linear;
}

.my-fadeout {
  animation: my-fadeout 150ms linear;
}
</style>
