<script setup lang="ts">
import {onMounted, ref, watch} from "vue";
import backendCall from "../../services/AxiosService";

const requests = ref([null, []]);
const requestsToPrint = ref();


const fetchRequests = async () => {
  const fetchRequestsCall = await backendCall.get("/requests");
  requests.value[0] = fetchRequestsCall.data.data;
}

watch(requests, (newRequests) => {

  requestsToPrint.value = newRequests?.[1].map((request) => parseInt(request.request_id));

}, {immediate: true})


const generatePDF = async () => {
  try {

    const requestObject = {requests: requestsToPrint.value}
    const printRequestsCall = await backendCall.post("/requests/generatepdf", requestObject, {
      responseType: "blob"
    });

    // Extract filename from Content-Disposition header
    const contentDisposition = printRequestsCall.headers['content-disposition']
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(contentDisposition)
    let filename = contentDisposition // default filename
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '')
    }

    if (printRequestsCall) {

// Create a Blob from the PDF Stream
//       const file = new Blob([printRequestsCall.data], {type: 'application/pdf'})

      // Create a Blob URL
      const blobUrl = URL.createObjectURL(printRequestsCall.data)

      // Create a hidden anchor element
      const link = document.createElement('a')
      link.href = blobUrl
      link.title = filename
      link.target = '_blank'
      link.style.display = 'none'
      document.body.appendChild(link)

      // Programmatically click the link
      link.click()

      // Clean up
      document.body.removeChild(link)
      URL.revokeObjectURL(blobUrl)


      // // Alternatively, to trigger a download:
      // const link = document.createElement('a')
      // link.href = fileURL
      // link.setAttribute('download', 'generated-document.pdf')
      // document.body.appendChild(link)
      // link.click()
      // document.body.removeChild(link)

    } else {
      console.error('PDF generation failed')
      // Handle error (e.g., show error message to user)
    }
  } catch (error) {
    console.error('An error occurred', error)
    // Handle error (e.g., show error message to user)
  }
}


onMounted(async () => {
  await fetchRequests();

})

</script>

<template>
  <div class="bg-surface-0 dark:bg-surface-900 p-6 shadow rounded-border">
    <div class="mb-4 flex items-center justify-between">
      <span class="text-xl font-medium text-surface-900 dark:text-surface-0">Print Requests</span>
      <!--    <Button @click="dismountClipDialogVisible = true" outlined label="Dismount" icon="pi pi-wrench"></Button>-->
    </div>
    <div class="border-2 border-solid rounded-xl border-surface">

      <div class="card flex flex-col items-center">
        <PickList v-model="requests" scroll-height="500px" dataKey="request_id" breakpoint="1400px">
          <template #option="{ option  }">
            {{new Date(option.created_at).toLocaleDateString() }} - {{ option.request_id}} - {{ option.equipment_id }} - {{ option.request_notes }}
          </template>
        </PickList>
        <div class="flex justify-center mt-4 w-full">

          <Button @click="generatePDF" class="w-1/2" label="Print"></Button>
        </div>
      </div>

    </div>
  </div>

</template>