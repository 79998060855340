<script setup lang="ts">
import {onMounted, ref} from "vue";
import backendCall from "../../services/AxiosService";
import CreateYardCheckDialog from "../../components/yard-check/CreateYardCheckDialog.vue";

const createYardCheckDialogVisible = ref(false);
const yardChecks = ref();

const fetchYardChecks = async () => {
  const fetchYardChecksCall = await backendCall.get("/yard-check");
  yardChecks.value = fetchYardChecksCall.data.data;
}

onMounted(async () => {
  await fetchYardChecks();
})

</script>

<template>
  <div class="bg-surface-0 dark:bg-surface-900 p-6 shadow">
    <div class="mb-4 flex items-center justify-between">
      <div></div>
      <span class="text-xl font-medium text-surface-900 dark:text-surface-0">Yard Checks</span>
      <Button label="New" icon="pi pi-plus" @click="createYardCheckDialogVisible = true"></Button>
    </div>
    <div class="border-2 border-solid p-1 border-opacity-100 rounded-xl border-surface">
      <DataTable :value="yardChecks" paginator :rows="10" :rows-per-page-options="[10, 20, 50, 100]" data-key="id">
        <Column field="yard_check_id" header="ID"></Column>
        <Column header="Date">
          <template #body="slotProps">
            {{ new Date(slotProps.data.date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: 'UTC'
          }) }}
          </template>
        </Column>
        <Column field="morning_afternoon" header="M/A"></Column>
        <Column field="start_time" header="Started At">
          <template #body="slotProps">
            <div v-if="slotProps.data.start_time">
            {{new Date(slotProps.data.start_time).toLocaleString()}}
            </div>
          </template>
        </Column>
        <Column field="completed_at" header="Completed At">
          <template #body="slotProps">
            <div v-if="slotProps.data.completed_at">
            {{new Date(slotProps.data.completed_at).toLocaleString()}}
            </div>
          </template>
        </Column>
        <Column header="Actions">
          <template class="flex" #body="slotProps">
            <span class="justify-end">
               <RouterLink :to="`/yard-check/${slotProps.data.yard_check_id}`">
            <Button class="mx-1 my-2" label="View"/>
               </RouterLink>
             <RouterLink v-if="!slotProps.data.locked" :to="`/yard-check/${slotProps.data.yard_check_id}/perform`">
            <Button class="mx-1 my-2" label="Perform"/>
                </RouterLink>
              </span>
          </template>
        </Column>
        <Column header="Status">
          <template #body="slotProps">
            <i v-if="slotProps.data.locked" v-tooltip="'Locked'" class="pi pi-lock" style="color: orange"></i>
            <i v-else class="pi pi-lock-open" v-tooltip="'Unlocked'" style="color: greenyellow"></i>
            <i v-if="slotProps.data.complete" v-tooltip="'Complete'" class="pi pi-check ml-4" style="color: greenyellow"></i>
            <i v-else class="pi pi-check ml-4" v-tooltip="'Not Complete'" style="color: grey"></i>
            <i class="pi pi-info-circle ml-4 hover:cursor-pointer" @click=""></i>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
  <CreateYardCheckDialog v-model:visible="createYardCheckDialogVisible"
                         @visible-change="createYardCheckDialogVisible = !createYardCheckDialogVisible" @fetchYardChecks="fetchYardChecks"></CreateYardCheckDialog>
</template>

<style scoped>

</style>