<template>
  <div class="bg-surface-50 dark:bg-surface-950 overflow-y-auto">
    <div class="grid grid-cols-12 gap-4">

      <FieldUnitCard v-for="request in requests" :alarms="props.alarms" :unit-number="request.equipment_id" :is-request="true" :request-description="request.request_notes" :key="request.request_id"
                     :setpointString="request.complete_setpoint" :sequence="request.sequence" :unit-type="request.elec_diesel" @submit-unit-info="$emit('submitUnitInfo', $event)"/>
    </div>
  </div>
</template>
<script setup>
import FieldUnitCard from "./FieldUnitCard.vue";
const props = defineProps({requests: Array, alarms: Array});
const emits = defineEmits(['submitUnitInfo']);
</script>

<style scoped>
.unitlist-enter-active,
.unitlist-leave-active {
  transition: all 0.5s ease;
}
.unitlist-enter-from,
.unitlist-leave-to {
  opacity: 0;
  transition: all 0.5s ease;}
</style>