<template>
  <div class="bg-surface-50 dark:bg-surface-950 overflow-y-auto">
    <div class="grid grid-cols-12 gap-4">

      <FieldUnitCard v-for="domestic in domestics" :alarms="props.alarms" :unit-number="domestic.unit_number" :waybill-id="domestic.waybill_id" :key="domestic.waybill_id"
        :setpointString="domestic.complete_setpoint_f" :sequence="domestic.sequence" unit-type="domestic" @submit-unit-info="$emit('submitUnitInfo', $event)"/>

    </div>
  </div>
</template>
<script setup>
import FieldUnitCard from "./FieldUnitCard.vue";
const props = defineProps({domestics: Array, alarms: Array})
const emits = defineEmits(['submitUnitInfo']);

</script>

<style scoped>
.unitlist-enter-active,
.unitlist-leave-active {
  transition: all 0.5s ease;
}
.unitlist-enter-from,
.unitlist-leave-to {
  opacity: 0;
  transition: all 0.5s ease;}
</style>