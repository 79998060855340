<template>
  <Card>
    <template #header>
      <h3 class="text-center">{{headerTitle}}</h3>
    </template>
    <template #content>
      <DataTable show-gridlines striped-rows :value="units" size="small">
        <Column field="sequence" header="SEQ" :sortable="true" style="width: 5%" class="text-sm"></Column>
        <Column field="request_id" header="REQ ID" :sortable="true" style="width: 5%" class="text-sm"></Column>
<!--        <Column field="sequence" header="Seq" sortable style="width: 25%"></Column>-->
        <Column field="equipment_id" header="Unit" :sortable="true" style="width: 25%" class="text-sm">
          <template #body="slotProps">
            <Button @click="openUnitWaybillAndLocationDialog(slotProps.data)" text size="small">
              <div class="text-sm"> {{ slotProps.data?.equipment_id }} </div>
            </Button>
          </template>
        </Column>
        <Column field="complete_setpoint" header="Setpoint" :sortable="true" style="width: 25%" class="text-sm"></Column>
        <Column field="request_notes" header="Request" :sortable="true" style="width: 25%" class="text-sm"></Column>
        <Column header="actions" >
          <template #body="slotProps">
            <ConfirmPopup></ConfirmPopup>
            <div class="flex justify-content-centered">
              <Button  @click="$emit('open-subscribe-dialog', slotProps.data)" class="mx-1" icon="pi pi-bell" severity="warning" outlined rounded aria-label="Filter" />
<!--              <Button @click="confirm1($event)" icon="pi pi-times" severity="danger" outlined rounded aria-label="Cancel" />-->
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>

  <UnitWaybillAndLocationDialog :unit-info="selectedUnitInfo"  v-model:unit-waybill-and-location-dialog-visible="unitWaybillAndLocationDialogVisible"/>

</template>

<script setup>
import {onMounted, ref} from 'vue';
import ConfirmPopup from "primevue/confirmpopup";
import UnitWaybillAndLocationDialog from "../../waybill/UnitWaybillAndLocationDialog.vue";
// const units = ref();
const props = defineProps({
  headerTitle: String,
  units: {}
})

const emit = defineEmits(['open-subscribe-dialog']);

const unitWaybillAndLocationDialogVisible = ref(false);
const selectedUnitInfo = ref();

const openUnitWaybillAndLocationDialog = (data) => {
  unitWaybillAndLocationDialogVisible.value = true;
  selectedUnitInfo.value = data;
}

onMounted(() => {

});



</script>
