<script setup lang="ts">
import {useTrainsStore} from "../../stores/trainsStore";
import {useReferenceStore} from "../../stores/referenceStore";
import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import backendCall from "../../services/AxiosService";
import {useToast} from "primevue/usetoast";
import {useConfirm} from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
import TrainOutcomeModal from "../../components/trains/consist/TrainOutcomeModal.vue";

const visible = defineModel('visible');

const confirm = useConfirm();
const trainsStore = useTrainsStore();

let confirmDialogVisible = ref(false);

const props = defineProps({
  trainId: Number,
  trainComplete: Boolean,
  trainArrived: String,
  userTimeRecordOpen: Boolean,
  userTrackProtectionRecords: Array,
  trainActive: Boolean,
});

const emit = defineEmits(['trainCompleted']);

const isVisible = ref(false);
const trainOutcomeModalVisible = ref(false);

const toggleSlideover = () => {
  isVisible.value = !isVisible.value;
};

const trainStore = useTrainsStore();
const referenceStore = useReferenceStore();

const toast = useToast();

const trackSuggestions = ref<string[]>([]);
const allTracks = ref();


const searchTracks = (event: { query: string }) => {
  const query = event.query.trim().toUpperCase();

  if (query.length === 0) {
    trackSuggestions.value = [...allTracks.value];
  } else {
    trackSuggestions.value = allTracks.value.filter((track) =>
        track.track_id.toUpperCase().startsWith(query) || track.track_name.toUpperCase().startsWith(query)
    );
  }
};
const timeTrackerData = ref({
  trainArrived: '',
  waitTimeStarted: false,
  timeStarted: props.userTimeRecordOpen || false,
  protectionStarted: false,
  protectionEnded: false,
  protectionTracks: [],
  trainComplete: false,
  trainArrivalLocation: null,
});

const trainArrivalLocationOptions = ref(["TRANSCONA", "SUBWAY", "SYMINGTON", "FT-ROUGE"])


const elapsedTotalTime = ref(0);
const totalTimeStartTime = ref(null);
let totalTimeTimerInterval: number | null = null;

const elapsedWaitTime = ref(0);
const waitTimeStartTime = ref(null);
let waitTimeTimerInterval: number | null = null;


const fetchCurrentTotalTime = async () => {
  try {
    const response = await backendCall.get(`time/train/${props.trainId}/current`);
    if (response.data.totalTime.isRunning) {
      totalTimeStartTime.value = new Date(response.data.totalTime.startTime);
      elapsedTotalTime.value = Math.floor((new Date() - totalTimeStartTime.value) / 1000);
      startTotalTimeTimer()
    } else {
      elapsedTotalTime.value = response.data.elapsedTime || 0;
    }
    console.log(response.data);
    if (response.data.waitTime.isRunning === true) {
      timeTrackerData.value.waitTimeStarted = true;
      waitTimeStartTime.value = new Date(response.data.waitTime.startTime);
      elapsedWaitTime.value = Math.floor((new Date() - waitTimeStartTime.value) / 1000);
      startWaitTimeTimer()
    }

    if (timeTrackerData.value.protectionStarted) {
      trackProtectionTimeStartTime.value = new Date(response.data.trackProtectionTime.startTime);
      elapsedTrackProtectionTime.value = Math.floor((new Date() - trackProtectionTimeStartTime.value) / 1000);
      startTrackProtectionTimeTimer()
    }


  } catch (error) {
    console.error('Error fetching current time:', error);
    toast.add({severity: 'error', summary: 'Error!', detail: 'Error fetching current total time!', life: 5000});
  }
};


const startTotalTimeTimer = () => {
  if (totalTimeTimerInterval) clearInterval(totalTimeTimerInterval);
  totalTimeTimerInterval = setInterval(() => {
    elapsedTotalTime.value = Math.floor((new Date() - totalTimeStartTime.value) / 1000);
  }, 1000);
};

const stopTotalTimeTimer = () => {
  if (totalTimeTimerInterval) {
    clearInterval(totalTimeTimerInterval);
    totalTimeTimerInterval = null;
  }
};

// wait time timer

const startWaitTimeTimer = () => {
  if (waitTimeTimerInterval) clearInterval(waitTimeTimerInterval);
  waitTimeTimerInterval = setInterval(() => {
    elapsedWaitTime.value = Math.floor((new Date() - waitTimeStartTime.value) / 1000);
  }, 1000);
};

const stopWaitTimeTimer = () => {
  if (waitTimeTimerInterval) {
    clearInterval(waitTimeTimerInterval);
    waitTimeTimerInterval = null;
  }
};

// track protection timer
const elapsedTrackProtectionTime = ref(0);
const trackProtectionTimeStartTime = ref(null);
let trackProtectionTimeTimerInterval: number | null = null;


const startTrackProtectionTimeTimer = () => {
  if (trackProtectionTimeTimerInterval) clearInterval(trackProtectionTimeTimerInterval);
  trackProtectionTimeTimerInterval = setInterval(() => {
    elapsedTrackProtectionTime.value = Math.floor((new Date() - trackProtectionTimeStartTime.value) / 1000);
  }, 1000);
};

const stopTrackProtectionTimeTimer = () => {
  if (trackProtectionTimeTimerInterval) {
    clearInterval(trackProtectionTimeTimerInterval);
    trackProtectionTimeTimerInterval = null;
  }
};

onMounted(async () => {
  await fetchCurrentTotalTime();
  if (!allTracks.value) {
    await referenceStore.fetchReferenceData()
  }

});

onUnmounted(() => {
  stopTotalTimeTimer();
});


watch(() => props.userTrackProtectionRecords, (newValue) => {
  timeTrackerData.value.protectionTracks = newValue;
  timeTrackerData.value.protectionStarted = newValue.length > 0;
})

watch(() => props.trainComplete, (newValue) => {
  timeTrackerData.value.trainComplete = newValue;
});

watch(() => props.trainArrived, (newValue) => {
  timeTrackerData.value.trainArrived = newValue;
});

watch(() => props.userTimeRecordOpen, (newValue) => {
  timeTrackerData.value.timeStarted = newValue;
});

watch(() => referenceStore.tracks, (newValue) => {
  allTracks.value = newValue;
});

const showCompletionButtons = computed(() => !timeTrackerData.value.trainComplete);

const toggleTrainArrived = async () => {
  try {

    const trainArrivalCall = await backendCall.post(`trains/${props.trainId}/arrival`);
    timeTrackerData.value.trainArrived = true;
    timeTrackerData.value.trainArrived = trainArrivalCall.data.arrivalTime;
  } catch (error) {
    toast.add({severity: 'error', summary: 'Error!', detail: 'Error submitting Train Arrival!', life: 5000});
    console.error('Error submitting train arrival:', error);
    throw error;
  }
};

const toggleBeginTotalTime = async () => {
  try {
    const data = {type: 'train', train: props.trainId};
    const response = await backendCall.post('time/start', data)
    timeTrackerData.value.timeStarted = true;
    console.log(response)
    totalTimeStartTime.value = new Date(response.data.startTime);
    elapsedTotalTime.value = 0;
    startTotalTimeTimer();
    return true;
  } catch (error) {
    toast.add({severity: 'error', summary: 'Error!', detail: 'Error Starting Time!', life: 5000});
    console.error('Error submitting train arrival:', error);
    throw error;
  }
};

const toggleEndTotalTime = async () => {
  try {
    const data = {type: 'train', train: props.trainId}
    const response = await backendCall.post('time/end', data)
    timeTrackerData.value.timeStarted = false;
    elapsedTotalTime.value = response.data.elapsedTime;
    stopTotalTimeTimer()
    return true;
  } catch (error) {
    toast.add({severity: 'error', summary: 'Error!', detail: 'Error Stopping Time!', life: 5000});
    console.error('Error submitting train arrival:', error);
    throw error;
  }
};

const startWaitTime = async () => {
  try {
    const data = {type: 'train', train: props.trainId};
    const response = await backendCall.post('time/wait-start', data)
    timeTrackerData.value.waitTimeStarted = true;
    waitTimeStartTime.value = new Date(response.data.startTime);
    elapsedWaitTime.value = 0;
    startWaitTimeTimer()
    return true;
  } catch (error) {
    toast.add({severity: 'error', summary: 'Error!', detail: 'Error Starting Wait Time!', life: 5000});
    console.error('Error submitting train arrival:', error);
    throw error;
  }
};

const endWaitTime = async () => {
  try {
    const data = {type: 'train', train: props.trainId};
    await backendCall.post('time/wait-end', data)
    timeTrackerData.value.waitTimeStarted = false;
    stopWaitTimeTimer()
    return true;
  } catch (error) {
    toast.add({severity: 'error', summary: 'Error!', detail: 'Error Starting Wait Time!', life: 5000});
    console.error('Error submitting wait time end:', error);
    throw error;
  }
};

const startProtection = async () => {
  try {
    const data = {type: 'train', train: props.trainId, tracks: timeTrackerData.value.protectionTracks};
    await backendCall.post('time/track-protection-start', data)
    timeTrackerData.value.protectionStarted = true;
    return true;
  } catch (error) {
    toast.add({severity: 'error', summary: 'Error!', detail: 'Error Starting Track Protection!', life: 5000});
    console.error('Error starting track protection', error);
    throw error;
  }
};

const endProtection = async () => {
  try {
    const data = {type: 'train', train: props.trainId, tracks: timeTrackerData.value.protectionTracks};
    await backendCall.post('time/track-protection-end', data)
    timeTrackerData.value.protectionStarted = false;
    timeTrackerData.value.protectionTracks = [];
    stopTrackProtectionTimeTimer()
    return true;
  } catch (error) {
    toast.add({severity: 'error', summary: 'Error!', detail: 'Error Starting Track Protection!', life: 5000});
    console.error('Error starting track protection', error);
    throw error;
  }
};

const completeTrain = async () => {
  try {
    const postResult = await backendCall.post(`trains/${props.trainId}/outcome`, {result: 'completed', location: timeTrackerData.value.trainArrivalLocation});
    if (!postResult) {
      throw new Error('Could Not Submit Train Result')
    }
    emit('trainCompleted', true);
    toast.add({severity: 'success', summary: 'Confirmed', detail: 'Train Marked Complete!', life: 5000});
    trainsStore.fetchTrains()
  } catch (e) {
    toast.add({
      severity: 'error',
      summary: 'Could not submit train outcome!',
      detail: 'An error occurred while submitting the train outcome.' + e,
      life: 5000
    });

  }
};

const confirmCompleteTrain = () => {
  confirmDialogVisible.value = true
  confirm.require({
    group: 'confirm-dialog',
    target: '#body',
    message: 'Confirm Train Complete?',
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Confirm'
    },
    accept: () => {
      completeTrain()
    },
    reject: () => {

    }
  });
};


</script>
<template>
  <ConfirmDialog group="confirm-dialog"></ConfirmDialog>

  <div v-if="visible">
    <TrainOutcomeModal v-model:submit-outcome-modal-visible="trainOutcomeModalVisible"
                       @visible-change="trainOutcomeModalVisible = !trainOutcomeModalVisible"></TrainOutcomeModal>
    <div id="slideover-8"
         class="bg-white dark:bg-black z-50 bg-surface-0 h-full absolute top-0 right-0 shadow w-80"
         style="z-index: 101">
      <div class="flex flex-col h-full">
        <div class="flex items-center justify-between mb-6 p-6">
          <span class="text-surface-900 dark:text-surface-0 text-xl font-medium">Time Tracking</span>
          <Button @click="visible = false" icon="pi pi-times"
                  class="p-button-rounded p-button-text p-button-plain"></Button>
        </div>
        <div class="flex-auto overflow-y-auto">
          <ul class="list-none p-0 m-0">
            <li class="flex items-center border-t border-surface p-4">
                         <span v-if="timeTrackerData.timeStarted"
                               class="rounded-full bg-green-500 text-white text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                               style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
              <span v-else
                    class="rounded-full border-primary bg-white text-black text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                    style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
              <span class="text-surface-700 dark:text-surface-100 font-medium">
            <Button v-if="!timeTrackerData.timeStarted" @click="toggleBeginTotalTime" label="Begin Time" text
                    outlined></Button>
              <span v-else>
              <Button @click="toggleEndTotalTime" label="Stop Time" severity="danger" text outlined></Button>
                              <span>{{
                                  Math.floor(elapsedTotalTime / 3600).toString().padStart(2, '0')
                                }}:{{
                                  Math.floor((elapsedTotalTime % 3600) / 60).toString().padStart(2, '0')
                                }}:{{ (elapsedTotalTime % 60).toString().padStart(2, '0') }}</span>
              </span>

            </span>
            </li>
            <li class="flex items-center border-t border-surface p-4">
                          <span v-if="timeTrackerData.waitTimeStarted"
                                class="rounded-full bg-green-500 text-white text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                                style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
              <span v-else
                    class="rounded-full border-primary bg-white text-black text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                    style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
              <span class="text-surface-700 dark:text-surface-100 font-medium">
              <Button v-if="!timeTrackerData.waitTimeStarted" @click="startWaitTime" label="Start Wait Time" text
                      outlined></Button>
                <span v-else>
               <Button @click="endWaitTime" severity="danger" label="Stop Wait Time" text outlined></Button>
                             <span>{{
                                 Math.floor(elapsedWaitTime / 3600).toString().padStart(2, '0')
                               }}:{{
                                 Math.floor((elapsedWaitTime % 3600) / 60).toString().padStart(2, '0')
                               }}:{{ (elapsedWaitTime % 60).toString().padStart(2, '0') }}</span>
              </span>
           </span>
            </li>
            <li class="flex flex-col items-start border-t border-surface p-4">
              <div class="flex items-center w-full">
                <div v-if="timeTrackerData.trainArrived"
                     class="rounded-full bg-green-500 text-white text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                     style="width:35px;height:35px">
                  <i class="pi pi-check"></i>
                </div>
                <span v-else
                      class="rounded-full border-primary bg-white text-black text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                      style="width:35px;height:35px">
      <i class="pi pi-check"></i>
    </span>
                <span class="text-surface-700 dark:text-surface-100 font-medium">
      <Button @click="toggleTrainArrived" :disabled="timeTrackerData.trainArrived" label="Train Arrived" text
              outlined></Button>
    </span>
              </div>
              <div v-if="timeTrackerData.trainArrived" class="mt-2 ml-10">
                {{ new Date(timeTrackerData.trainArrived).toLocaleString('en-US', {hourCycle: 'h23'}) }}
              </div>
            </li>
            <li class="flex flex-col justify-evenly border-t border-surface p-4">
              <div>
                <!--              <InputText id="track-input" v-model="timeTrackerData.protectionTrack" :disabled="timeTrackerData.protectionStarted"-->
                <!--                         placeholder="Track"></InputText>-->
                <AutoComplete
                    :multiple="true"
                    v-model="timeTrackerData.protectionTracks"
                    :suggestions="trackSuggestions"
                    :optionLabel="(trackSuggestions) => trackSuggestions.track_id"
                    :forceSelection="false"
                    @complete="searchTracks"
                    placeholder="Select tracks"
                    :disabled="timeTrackerData.protectionStarted"
                />
              </div>

              <div class="mt-2">
                    <span v-if="timeTrackerData.protectionStarted"
                          class="rounded-full bg-green-500 text-white text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                          style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
                <span v-else
                      class="rounded-full border-primary bg-white text-black text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                      style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
                <span class="text-surface-700 dark:text-surface-100 font-medium">
              <Button @click="startProtection"
                      :disabled="timeTrackerData.protectionStarted || !timeTrackerData.protectionTracks.length"
                      label="Protection Start"
                      text
                      outlined></Button>
            </span>
              </div>
            </li>
            <li class="flex items-center border-t border-surface p-4">
                    <span v-if="timeTrackerData.protectionEnded"
                          class="rounded-full bg-green-500 text-white text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                          style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
              <span v-else
                    class="rounded-full border-primary bg-white text-black text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                    style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
              <span class="text-surface-700 dark:text-surface-100 font-medium">
              <Button @click="endProtection" label="Protection End" text outlined></Button>
            </span>
            </li>
            <li class="flex justify-center items-center border-t border-surface p-4">
              <!--                    <span v-if="timeTrackerData.trainComplete"-->
              <!--                          class="rounded-full bg-green-500 text-white text-2xl inline-flex items-center justify-center mr-4 border border-transparent"-->
              <!--                          style="width:35px;height:35px">-->
              <!--                        <i class="pi pi-thumbs-up"></i>-->
              <!--                    </span>-->
              <div></div>
              <!--            <span v-else-->
              <!--                  class="rounded-full border-primary bg-white text-black text-2xl inline-flex items-center justify-center mr-4 border border-transparent"-->
              <!--                  style="width:35px;height:35px">-->
              <!--                        <i class="pi pi-thumbs-up"></i>-->
              <!--                    </span>-->
              <div  class=" flex text-center text-surface-700 dark:text-surface-100 gap-3 font-medium">
                <div v-if="showCompletionButtons" class="flex flex-col text-center text-surface-700 dark:text-surface-100 gap-3 font-medium">
                  <div>
                    <label for="train-arrival-location" class="block mb-1 text-color text-base">Arrival Location</label>
                    <Select class="w-full" v-model="timeTrackerData.trainArrivalLocation"
                            :options="trainArrivalLocationOptions" name="train-arrival-location"
                            id="train-arrival-location"/>
                  </div>
                  <div class="flex text-center text-surface-700 dark:text-surface-100 gap-3 font-medium">
                  <Button @click="confirmCompleteTrain()" :disabled="!timeTrackerData.trainArrivalLocation" label="Complete"
                          :outlined="!timeTrackerData.trainComplete"></Button>
                  <!--               <Button @click="completeTrain" label="Denied" severity="warn" size="small" outlined></Button>-->
                  <Button @click="trainOutcomeModalVisible = true" label="Not Completed"
                          severity="danger" size="small" outlined></Button>
                  </div>
                </div>
                <Tag v-else>Train Result Submitted</Tag>
              </div>

            </li>

            <!--          <li class="flex items-center border-t border-surface p-4">-->
            <!--                    <span class="rounded-full text-2xl inline-flex items-center justify-center mr-4 border border-surface" style="width:35px;height:35px">-->
            <!--                        <i class="pi pi-thumbs-up text-surface-700 dark:text-surface-100"></i>-->
            <!--                    </span>-->
            <!--            <span class="text-surface-700 dark:text-surface-100 font-medium">Confirmation</span>-->
            <!--          </li>-->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

@keyframes my-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes my-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

</style>