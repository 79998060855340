<script setup>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import backendCall from "../../services/AxiosService";
import {ref, onMounted} from "vue";                   // optional

const historicTrains = ref();

const fetchTrains = () => {
  backendCall.get('trains/history')
      .then(async response => {
        const data = response.data;
        if (Array.isArray(data.content)) {
           historicTrains.value = data.content.map(train => ({
            ...train,
            origin_depart_date_string: new Date(train.train_origin_departure_date).toDateString(),
            eta_string: train.eta ? new Date(train.eta).toLocaleString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hourCycle: 'h23',
              timeZone: 'UTC'
            }) : null,
            location: '',
          }));

        } else {
          throw new Error('Data content is not an array');
        }
      })
      .catch(error => {
        console.error('There was a problem with the axios operation:', error);
      });
}

onMounted(() => {
fetchTrains()
})

</script>

<template>
  <div class="card">
    <DataTable :value="historicTrains" striped-rows removableSort sortField="train_row_id" paginator :rows="10" :rowsPerPageOptions="[10, 20, 50, 100]" tableStyle="min-width: 50rem">
      <template #header>
        <div class="flex flex-wrap items-center justify-between gap-2">
          <span class="text-xl text-surface-900 dark:text-surface-0 font-bold">Train History</span>
          <Button @click="fetchTrains" icon="pi pi-refresh" rounded raised />
        </div>
      </template>
      <Column field="dashed_train_id" header="Train-ID">
        <template #body="slotProps">
          <router-link :to="'/trains/' + slotProps.data.train_row_id">{{slotProps.data.dashed_train_id}}</router-link>
        </template>
      </Column>
<!--      <Column header="Origin Date">-->
<!--        <template #body="slotProps">-->
<!--          <img :src="`https://primefaces.org/cdn/primevue/images/product/${slotProps.data.image}`" :alt="slotProps.data.image" class="w-24 rounded-border" />-->
<!--        </template>-->
<!--      </Column>-->
<!--      <Column field="price" header="Arrival Time">-->
<!--        <template #body="slotProps">-->
<!--          {{ formatCurrency(slotProps.data.price) }}-->
<!--        </template>-->
<!--      </Column>-->
      <Column field="eta" :sortable="true" header="ETA">
        <template #body="slotProps">
          {{slotProps.data.eta_string}}
        </template>
      </Column>
      <Column field="midpoint_stop_location" header="Location"></Column>
      <Column field="midpoint_arrival_time" :sortable="true" header="Arrival Time">
        <template #body="slotProps">
        {{ slotProps.data.midpoint_arrival_time ? new Date(slotProps.data.midpoint_arrival_time).toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hourCycle: 'h23',
        timeZone: 'America/Winnipeg'
        }) : '' }}
        </template>
      </Column>
      <Column field="gensetCount" header="Powerpacks"></Column>
      <Column field="electricCount" header="Electrics"></Column>
      <Column field="requestCount" header="Requests"></Column>
      <Column field="dieselCount" header="Domestics"></Column>
<!--      <Column field="rating" header="Status">-->
<!--        <template #body="slotProps">-->
<!--          <Rating :modelValue="slotProps.data.rating" readonly :cancel="false" />-->
<!--        </template>-->
<!--      </Column>-->
<!--      <Column header="Actions">-->
<!--        <template #body="slotProps">-->
<!--          <Tag :value="slotProps.data.inventoryStatus" :severity="getSeverity(slotProps.data)" />-->
<!--        </template>-->
<!--      </Column>-->
<!--      <template #footer> In total there are {{ products ? products.length : 0 }} products. </template>-->
    </DataTable>
  </div>
</template>

<style scoped>

</style>