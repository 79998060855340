import {createRouter, createWebHistory} from 'vue-router'
import Trainlineup from "../components/Trainlineup.vue";
// import NewTrain from "@/components/trains/NewTrain.vue";
// import TrainEtaUpdate from "@/components/trains/TrainEtaUpdate.vue";
// import TrainConsist from "@/components/trains/TrainConsist.vue";
// import SearchCN from "@/components/unit-lookup/SearchCN.vue";
// import WaybillSearch from "@/components/waybill/WaybillSearch.vue";
// import UnitCardContainer from "@/components/UnitCardContainer.vue";
import loginPage from '../views/auth/Login.vue';
import cnUnitQuery from "../views/CnUnitQuery.vue";
import Consistpage from "../views/trains/Consistpage.vue";
import MainLayout from "../layout/MainLayout.vue";
import NotFoundPage from "../views/errors/NotFoundPage.vue";
import RequestList from "../views/requests/CurrentRequestList.vue";
import FuelLedger from "../views/fuel/FuelLedger.vue";
import UserProfile from "../views/user/UserProfile.vue";
import AdminPanel from "../views/admin/AdminPanel.vue";
import StatisticsPage from "../views/reports/StatisticsPage.vue";
import clipDashboard from "../views/clipons/ClipDashboard.vue";
import Calendar from "../views/Calendar.vue";
import TrainLineupDataView from "../components/TrainLineupDataView.vue";
import AddBulkElectrics from "../views/trains/AddBulkElectrics.vue";
import MySubscriptions from "../views/user/MySubscriptions.vue";
import FieldTrainCheck from "../views/trains/FieldTrainCheck.vue";
import TrainTime from "../views/billing/TrainTime.vue";
import TrainHistory from "../views/trains/History.vue";
import PasteableLineup from "../views/trains/Pasteable-Lineup.vue";
import YardCheckDashboard from "../views/yardcheck/YardCheckDashboard.vue";
import PerformYardCheck from "../views/yardcheck/PerformYardCheck.vue";
import ViewYardCheck from "../views/yardcheck/ViewYardCheck.vue";
import RequestPrintConfiguration from "../views/requests/RequestPrintConfiguration.vue";
import JobsView from "../views/billing/JobsView.vue";
import WhosIn from "../views/staffing/WhosIn.vue";
import StatusSummary from "../views/fleet/StatusSummary.vue";
import MyTimes from "../views/user/MyTimes.vue"
import Forbidden from "../views/errors/Forbidden.vue";
import {useAuthStore} from "../stores/authStore.js";


const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            component: MainLayout,
            meta: {requiresAuth: true},
            children: [
                {path: '', component: TrainLineupDataView},
                {path: 'trains', name: 'TrainLineup',component: TrainLineupDataView},
                {path: 'trains/history', component: TrainHistory},
                {path: 'trains/:trainid', component: Consistpage, props: true},
                {path: 'trains/:trainid/time', component: TrainTime, props: true},
                {path: 'trains/old', component: Trainlineup},
                {path: 'unitquery', name: 'unitQueryPage', component: cnUnitQuery},
                {path: 'requests', component: RequestList},
                {path: 'requests/print-setup', name: 'requestPrintConfiguration', component: RequestPrintConfiguration},
                {path: 'fuel', name: FuelLedger, component: FuelLedger},
                {path: 'user/profile', name: 'userProfile', component: UserProfile},
                {path: 'admin', name: 'adminPanel', component: AdminPanel},
                {path: '/reports/statistics', name: 'statisticsReport', component: StatisticsPage},
                {path: '/clipons', name: 'cliponDashboard', component: clipDashboard},
                {path: '/calendar', name: 'calendarComponent', component: Calendar},
                {path: 'trains/:trainid/bulkelectrics', name: 'BulkElectrics', component: AddBulkElectrics},
                // {path: 'trains/:trainid/bulkelectrics2', name: 'BulkElectrics2', component: AddBulkElectrics2},
                {path: 'user/subscriptions', name: 'UserSubscriptions', component: MySubscriptions},
                {path: 'user/times', name: 'MyTimes', component: MyTimes},
                {path: 'billing/jobs', name: 'JobView', component: JobsView},
                {path: 'trains/lineup/pasteable', name: 'PasteableLineup', component: PasteableLineup},
                {path: 'yard-check', name: 'YardCheckDashboard', component: YardCheckDashboard},
                {path: 'yard-check/:yardCheckId/perform', name: 'PerformYardCheck', component: PerformYardCheck, props: true},
                {path: 'yard-check/:yardCheckId', name: 'ViewYardCheck', component: ViewYardCheck, props: true},
                {path: 'staffing/whos-in', name: 'WhosIn', component: WhosIn},
                {path: 'forbidden', name: 'Forbidden', component: Forbidden},
                {path: 'fleet/summary', name: 'FleetSummary', component: StatusSummary},



                // More authenticated routes can be nested here
            ]
        },
        {
            // The login page is outside the MainLayout
            name: 'login',
            path: '/login',
            component: loginPage,
            meta: {requiresUnauthenticated: true}
        },
        {path: '/trains/:trainid/fieldui', component: FieldTrainCheck, props: true, meta: {requiresAuth: true}},
        {path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFoundPage},
    ]
})

router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();

    if (to.meta.requiresAuth) {
        await authStore.checkAuthStatus();
        if (authStore.isAuthenticated) {
            next();
        } else {
            next('/login');
        }
    } else if (to.meta.requiresUnauthenticated && authStore.isAuthenticated) {
        next('/');
    } else {
        next();
    }
});


export default router
