<template>
    <DataTable :value="trains" v-model:selection="selectedTrains" data-key="train_row_id" size="large"  rowHover>
      <template #header>
        <div class="flex flex-wrap items-center justify-between gap-2">
          <span class="text-xl text-surface-900 dark:text-surface-0 font-bold">Trains</span>
          <div class="flex items-center gap-4">
            <Button severity="success" label="New" icon="pi pi-plus" />
          <Button @click="fetchTrains" icon="pi pi-refresh" rounded raised/>
          </div>
        </div>
      </template>
      <Column selection-mode="multiple"></Column>
      <Column field="train_id" header="Train ID" style="min-width:9rem; min-height: 25rem;" frozen>
        <template #body="{ data }">
          <div class="flex items-center gap-4">
            <div>
              <p class="mt-0 mb-2 font-medium text-lg text-color-primary">
                <router-link :to="`/trains/${data.train_row_id}`">
                  <Button size="small" :label="data.dashed_train_id" text outlined severity="info"/>
                </router-link>
              </p>
              <p class="mt-0 mb-2 font-normal text-base text-muted-color">{{ data.location }}</p>
            </div>
          </div>


        </template>
      </Column>
      <!--        <Column header="Image">-->
      <!--          <template #body="slotProps">-->
      <!--            <img :src="`https://primefaces.org/cdn/primevue/images/product/${slotProps.data.image}`" :alt="slotProps.data.image" class="w-24 rounded-border" />-->
      <!--          </template>-->
      <!--        </Column>-->
      <Column field="origin_depart_date_string" header="Origin Date">
        <!--&lt;!&ndash;          {{ slotProps.data.train_id }}&ndash;&gt;-->
        <!--          <template #body="slotProps">-->
        <!--          </template>-->
      </Column>
      <Column field="eta_string" header="ETA (From CN)"></Column>
      <Column field="location" header="Location">
        <!--          <template #body="slotProps">-->
        <!--&lt;!&ndash;            <Tag :value="slotProps.data.inventoryStatus" :severity="getSeverity(slotProps.data)" />&ndash;&gt;-->
        <!--          </template>-->
      </Column>
      <Column field="algorithm_eta" header="ETA Transcona (Experimental)">
        <template #body="slotProps">
          <!--            <Tag :value="slotProps.data.inventoryStatus" :severity="getSeverity(slotProps.data)" />-->
        </template>
      </Column>
      <template #footer> In total there are {{ trains ? trains.length : 0 }} trains.</template>
    </DataTable>
</template>


<style scoped>

</style>

<script setup>
import {useAuthStore} from '../stores/authStore';
import {ref, onMounted, onUnmounted} from 'vue';
import backendCall from "../services/AxiosService";

const authStore = useAuthStore();
const trains = ref([]);
const fetchTrainsInterval = ref(null);
const selectedTrains = ref(null);

// let index = t.train_id.length - 2;
// let dashedTrainId = t.train_id.slice(0, index) + "-" + t.train_id.slice(index);
//todo currently pointing to production backend but there is a data issue, trains are not showing up.
//todo Backend is also returning 403 instead of 401 on prod causing auto logout not to work.
const fetchTrains = () => {
  backendCall.get('trains/lineup', {
    headers: {
      "authorization": `Bearer ${authStore.token}`,
    }
  })
      .then(response => {
        const data = response.data;
        if (Array.isArray(data.content)) {
          const newTrains = data.content.map(train => ({
            ...train,
            origin_depart_date_string: new Date(train.train_origin_departure_date).toDateString(),
            eta_string: train.eta ? new Date(train.eta).toLocaleString() : null,
            location: '',
          }));

          // Check if new data is different from current data
          if (JSON.stringify(newTrains) !== JSON.stringify(trains.value)) {
            trains.value = newTrains;
          }

          newTrains.forEach((train, index) => {
            fetchLocations(train.train_row_id, index);
          });
        } else {
          throw new Error('Data content is not an array');
        }
      })
      .catch(error => {
        console.error('There was a problem with the axios operation:', error);
      });
}

const fetchLocations = (trainId, index) => {
  backendCall.get(`trains/locationbyid?trainid=${trainId}`, {
    headers: {
      "authorization": `Bearer ${authStore.token}`,
    }
  })
      .then(response => {
            const data = response.data.data;
            const newLocation = `${data.description} ${data.station} ${data.provstate} @ ${data.time}`;
            const currentLocation = trains.value[index].location;

            // Only update if the data has changed
            if (newLocation !== currentLocation) {
              trains.value[index].location = newLocation;
              trains.value[index].algorithm_eta = data.etaTime;
            }
          }
      )
      .catch(error => {
        console.error('Error fetching location:', error);
        trains.value[index].location = 'Unavailable';
      })
      .then(() => {
        // Log trains value after fetching or in case of an error
      });
}

onMounted(() => {
  fetchTrains();
  fetchTrainsInterval.value = setInterval(fetchTrains, 60000);
});

onUnmounted(() => {
  clearInterval(fetchTrainsInterval.value)
})
</script>
