<script setup lang="ts">
import {useAuthStore} from "../../stores/authStore";
import{useReferenceStore} from "../../stores/referenceStore";
import router from "../../router";
import backendCall from "../../services/AxiosService";
import {onMounted, ref} from "vue";
import ElectricCheckCards from "./ElectricCheckCards.vue";
import RequestCheckCards from "./RequestCheckCards.vue";
import DomesticCheckCards from "./DomesticCheckCards.vue";
import {useToast} from "primevue/usetoast";
import {createThemeHandler} from '../../utilities/themeHandler.ts'
import {useGeolocation} from '@vueuse/core'
import TimeTrackerSlideover from "./TimeTrackerSlideover.vue";

const authStore = useAuthStore();
const referenceStore = useReferenceStore();



const {coords, locatedAt, error, resume, pause} = useGeolocation()

const {isDarkMode} = createThemeHandler(authStore)

const props = defineProps({trainid: String});

const electrics = ref([]);
const domestics = ref([]);
const requests = ref([]);
const trainId = ref(null);
const activeTab = ref(0);
const toast = useToast();
const timeTrackerSlideoverVisible = ref(false);

const trainArrived = ref();
const waitTimeStarted = ref(false);
const timeStarted = ref(false);
const protectionStarted = ref(false);
const protectionEnded = ref(false);
const protectionTrack = ref(null);
const trainComplete = ref(false);
const userTimeRecordOpen=ref(false);
const userTrackProtectionRecords = ref([]);
const trainActive = ref();

function handleThemeChange(value) {
  isDarkMode.value = value
}

const handleTrainCompleted = (isCompleted: boolean) => {
  trainComplete.value = isCompleted;
  trainActive.value = !isCompleted;
};

const toggleTrainArrived = async () => {
  await backendCall.post(`trains/${props.trainid}/arrival`)
  trainArrived.value = true;
}

const toggleBeginTime = async () => {

  timeStarted.value = true;
}

const toggleEndTime = async () => {

  timeStarted.value = false;
}

const toggleWaitTime = () => {
  waitTimeStarted.value = !waitTimeStarted.value;
}

const startProtection = async () => {
  protectionStarted.value = true;
  protectionEnded.value = false;
}

const endProtection = () => {
  protectionStarted.value = false;
  protectionEnded.value = true;
  protectionTrack.value = null;
}


const getTrainUnits = async () => {

  await backendCall.get(`${import.meta.env.VITE_API_BASE_URL}trains/${props.trainid}/units`)
      .then(response => {

        return response;
      })
      .then(data => {
        trainId.value = data?.data.data.train_id_formatted;
        electrics.value = data?.data.data.electrics;
        domestics.value = data?.data.data.domestics;
        requests.value = data?.data.data.requests;
        trainComplete.value = data?.data?.data?.complete;
        trainArrived.value = data?.data?.data?.midpoint_arrival_time;
        userTimeRecordOpen.value = data?.data?.data?.user_time_record_open;
        userTrackProtectionRecords.value = data?.data?.data?.user_track_protection_records;
        trainActive.value = data?.data?.data?.active;

        console.log(data)

      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });

}

// Initialize as an empty array
const alarmSuggestions = ref<string[]>([]);
const allAlarms = ref<string[]>([]);

// Modify fetchAlarmList to update alarmSuggestions
const fetchAlarmList = async () => {
  try {
    const fetchAlarmListCall = await backendCall.get(`units/all-alarms`);
    allAlarms.value = [...fetchAlarmListCall.data.data];
    // Update alarmSuggestions here
    alarmSuggestions.value = [...allAlarms.value];
    console.log(allAlarms.value);
  } catch (error) {
    console.error("Error fetching alarm list:", error);
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Failed to fetch alarm list',
      life: 3000
    });
  }
}


const submitUnitInfoToServer = async (data) => {
  data.trainId = parseInt(props.trainid);
  await backendCall.post('unit-check', data);
  toast.add({severity: 'success', summary: 'Success', detail: data, life: 3000});


}

onMounted(async () => {
 await getTrainUnits()
  await fetchAlarmList()
  await referenceStore.fetchReferenceData()
})


</script>

<template>
  <div class="flex flex-col h-screen">
    <div class="bg-surface-0 dark:bg-surface-950 px-6 py-8 md:px-12 lg:px-20">
      <div class="flex border-solid border p-2 rounded-xl border-neutral-300 md:items-center justify-between md:flex-row">
        <div>
          <Button @click="router.push('/trains')" label="Back" class="p-button mr-2" icon="pi pi-arrow-left"></Button>
        </div>
        <div class="font-medium text-3xl text-surface-900 dark:text-surface-0">{{ trainId }}</div>
        <div class="mt-4 md:mt-0">
        <span class="flex mr-2">
            <i class="pi pi-sun mr-1 mt-1"></i>
            <ToggleSwitch v-if="authStore" class="flex p-3 lg:px-3 py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer
                        transition-duration-150 transition-colors p-ripple" id="darkmode-switch"
                          v-model="isDarkMode"></ToggleSwitch>
          <i class="pi pi-moon ml-1 mt-1"></i>
            </span>
        </div>
      </div>
    </div>
    <div class="my-6">
      <ul class="bg-surface-0 dark:bg-surface-900 p-0 m-0 list-none flex overflow-x-auto select-none">
        <li class="flex-1 flex justify-center">
          <a v-ripple
             class="cursor-pointer px-6 py-4 flex items-center justify-center border-b-2 hover:border-surface-500 dark:hover:border-surface-300 transition-colors duration-150 p-ripple"
             :class="{'border-primary-500 text-primary-500 hover:border-primary-500': activeTab === 0, 'text-700 border-transparent': activeTab !== 0}"
             @click="activeTab = 0">
            <Badge class="mr-3" :value="electrics.length"/>
            <span class="font-medium">Electrics</span>
          </a>
        </li>
        <li class="flex-1 flex justify-center">
          <a v-ripple
             class="cursor-pointer px-6 py-4 flex items-center justify-center border-b-2 hover:border-surface-500 dark:hover:border-surface-300 transition-colors duration-150 p-ripple"
             :class="{'border-primary-500 text-primary-500 hover:border-primary-500': activeTab === 1, 'text-700 border-transparent': activeTab !== 1}"
             @click="activeTab = 1">
            <Badge class="mr-3" :value="requests.length"/>
            <span class="font-medium">Requests</span>
          </a>
        </li>
        <li class="flex-1 flex justify-center">
          <a v-ripple
             class="cursor-pointer px-6 py-4 flex items-center justify-center border-b-2 hover:border-surface-500 dark:hover:border-surface-300 transition-colors duration-150 p-ripple"
             :class="{'border-primary-500 text-primary-500 hover:border-primary-500': activeTab === 2, 'text-700 border-transparent': activeTab !== 2}"
             @click="activeTab = 2">
            <Badge class="mr-3" :value="domestics.length"/>
            <span class="font-medium">Domestics</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="overflow-y-auto mx-6">
      <div v-show="activeTab === 0" class="col-span-12">
        <div>
          <ElectricCheckCards :electrics="electrics" :alarms="allAlarms" @submit-unit-info="submitUnitInfoToServer"/>
        </div>
      </div>

      <div v-show="activeTab === 1" class="col-span-12">
        <div>
          <RequestCheckCards :requests="requests" :alarms="allAlarms" @submit-unit-info="submitUnitInfoToServer"/>
        </div>
      </div>

      <div v-show="activeTab === 2" class="col-span-12">
        <div>
          <DomesticCheckCards :domestics="domestics" :alarms="allAlarms" @submit-unit-info="submitUnitInfoToServer"/>
        </div>
      </div>

    </div>
  </div>
  <Button @click="timeTrackerSlideoverVisible = true"
      class="!flex justify-center items-center !fixed w-[52px] h-[52px] bg-primary text-primary-content top-1/2 right-0 cursor-pointer outline-none rounded-[3px] shadow-lg"
      style="box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);">
    <i class="pi pi-clock leading-none"></i>
  </Button>
  <!--  Time Sidebar-->

  <TimeTrackerSlideover  @trainCompleted="handleTrainCompleted" v-model:visible="timeTrackerSlideoverVisible" :trainId="parseInt(props.trainid)" :train-complete="trainComplete" :train-arrived="trainArrived" :user-time-record-open="userTimeRecordOpen" :user-track-protection-records="userTrackProtectionRecords" :train-active="trainActive" />
</template>

<style scoped>

@keyframes my-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes my-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.my-hidden {
  display: none;
}

.my-fadein {
  animation: my-fadein 150ms linear;
}

.my-fadeout {
  animation: my-fadeout 150ms linear;
}
</style>