<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full">
    <body class="h-full">
    ```
  -->
  <div class="flex min-h-full flex-col bg-white dark:bg-neutral-950 pb-12 pt-16">
    <main class="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8">
      <div class="flex flex-shrink-0 justify-center">
        <a href="#" class="inline-flex">
          <span class="sr-only">RMS</span>
<!--          <img class="h-12 w-auto" src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600" alt="" />-->
        </a>
      </div>
      <div class="py-16">
        <div class="text-center">
          <p class="text-base font-semibold text-primary-600">403</p>
          <h1 class="mt-2 text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-200 sm:text-5xl">Forbidden</h1>
          <p class="mt-2 text-base text-gray-500">You do not have the necessary permissions to access this page.</p>
          <div class="mt-6">
            <router-link :to="{name: 'TrainLineup'}" class="text-base font-medium text-primary-600 hover:text-indigo-500">
              Go back home
              <span aria-hidden="true"> &rarr;</span>
            </router-link>
          </div>
        </div>
      </div>
    </main>
    <footer class="mx-auto w-full max-w-7xl flex-shrink-0 px-6 lg:px-8">
<!--      <nav class="flex justify-center space-x-4">-->
<!--        <a href="#" class="text-sm font-medium text-gray-500 hover:text-gray-600">Contact Support</a>-->
<!--        <span class="inline-block border-l border-gray-300" aria-hidden="true" />-->
<!--        <a href="#" class="text-sm font-medium text-gray-500 hover:text-gray-600">Status</a>-->
<!--      </nav>-->
    </footer>
  </div>
</template>
