import {defineStore} from 'pinia';
import backendCall from "../services/AxiosService";


export const useReferenceStore = defineStore({
    id: 'referenceStore', state: () => ({
        tracks: [],
        alarms: []
    }), actions: {
        fetchReferenceData() {
            backendCall.get('reference-data')
                .then(response => {
                    const referenceDataResponse = response.data.data;

                    this.tracks = referenceDataResponse.tracks;
                    this.alarms = referenceDataResponse.alarms;

                })
                .catch(error => {
                    console.error('There was a problem with the axios operation:', error);
                });
        }
    },

});
