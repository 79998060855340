<script setup lang="ts">
import {onMounted, ref, watch} from "vue";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import backendCall from "../../services/AxiosService";
import { useToast } from "primevue/usetoast";
import { tempWithinThreshold } from "../../utilities/tempValidation";
import { useReferenceStore } from "../../stores/referenceStore";

const referenceStore = useReferenceStore();
const toast = useToast();

interface TemperatureData {
  value: number | null;
  unit: 'C' | 'F' | null;
  rawInput: string;
}

interface UnitCheckData {
  unitNumber: string;
  billedSetpoint: TemperatureData;
  actualSetpoint: TemperatureData;
  returnTemp: TemperatureData;
  supplyTemp: TemperatureData;
  truckNumber: number | null;
  fuelAdded: number | null;
  startFuelLevel: string | null;
  endFuelLevel: string | null;
  alarms: any[];
  notes: string;
  eventCode: string | null;
  unitType: string | null;
}

const visible = defineModel('visible');
const props = defineProps({
  visible: Boolean,
  yardCheckId: Number,
  unitData: Object
});
const emit = defineEmits(['visible-change', 'refresh-yardcheck']);

const unitCheckData = ref<UnitCheckData>({
  unitNumber: '',
  billedSetpoint: { value: null, unit: null, rawInput: '' },
  actualSetpoint: { value: null, unit: null, rawInput: '' },
  returnTemp: { value: null, unit: null, rawInput: '' },
  supplyTemp: { value: null, unit: null, rawInput: '' },
  truckNumber: null,
  fuelAdded: null,
  startFuelLevel: null,
  endFuelLevel: null,
  alarms: [],
  notes: '',
  eventCode: null,
  unitType: null,
});

onMounted(() => {
  fetchAlarmList()
})

const alarmSuggestions = ref<string[]>([]);
const allAlarms = ref<string[]>([]);

// Modify fetchAlarmList to update alarmSuggestions
const fetchAlarmList = async () => {
  try {

    allAlarms.value = [...referenceStore.alarms];
    // Update alarmSuggestions here
    alarmSuggestions.value = [...allAlarms.value];
    // console.log(allAlarms.value);
  } catch (error) {
    console.error("Error fetching alarm list:", error);
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Failed to fetch alarm list',
      life: 3000
    });
  }
}

const searchAlarms = (event: { query: string }) => {
  const query = event.query.trim().toLowerCase();

  if (query.length === 0) {
    alarmSuggestions.value = [...allAlarms.value];
  } else {
    alarmSuggestions.value = allAlarms.value.filter((alarm) =>
        alarm.alarm_code.toLowerCase().includes(query) || alarm.alarm_name.toLowerCase().includes(query)
    );
  }
};

const fuelOptions = ref(['' ,'E', '1/8', '1/4', '3/8', '5/16', '7/16', '1/2', '9/16', '5/8', '3/4', '13/16', '7/8', 'F']);

const eventCodeOptions = ref(['IG', 'DR', 'AS', 'TA', 'PN', 'SW', 'RN']);

const unitTypeOptions = ref(['KR1', 'KR2', 'KR4', 'KR7', 'KH7']);

// Initialize form data from props
watch(() => props.unitData, (newData) => {
  if (newData) {
    unitCheckData.value = {
      unitNumber: newData.unit_number || '',
      billedSetpoint: parseTemperature(newData.billed_setpoint, newData.billed_setpoint_unit),
      actualSetpoint: parseTemperature(newData.actual_setpoint, newData.actual_setpoint_unit),
      returnTemp: parseTemperature(newData.return_temp, newData.return_temp_unit),
      supplyTemp: parseTemperature(newData.supply_temp, newData.supply_temp_unit),
      truckNumber: newData.vehicle_id || null,
      fuelAdded: newData.fuel_added || null,
      startFuelLevel: newData.start_fuel_level || null,
      endFuelLevel: newData.end_fl || null,
      alarms: newData.alarms || [],
      notes: newData.notes || '',
      eventCode: newData.event_code || null,
      unitType: newData.unit_type || null,
      inDefrost: newData.in_defrost || null,
      onRvr: newData.on_rvr || null,
    };
  }
}, { immediate: true, deep: true });

function parseTemperature(value: number | null, unit: string | null): TemperatureData {
  if (value === null || unit === null) {
    return { value: null, unit: null, rawInput: '' };
  }
  const rawInput = `${value}${unit}`;
  return { value, unit: unit as 'C' | 'F', rawInput };
}

const temperatureDiscrepancies = ref(new Set<string>());
const invalidFields = ref(new Set<string>());

function validateTemperatureField(field: keyof UnitCheckData): boolean {
  const data = unitCheckData.value[field] as TemperatureData;
  if (!data.rawInput) {
    invalidFields.value.delete(field);
    return true; // Consider empty field as valid
  }

  const match = data.rawInput.match(/^(-?\d+(\.\d+)?)(C|F)$/i);
  if (match) {
    const [, numPart, , unitPart] = match;
    const numValue = parseFloat(numPart);
    const unitValue = unitPart.toUpperCase() as 'C' | 'F';

    unitCheckData.value[field] = {
      value: numValue,
      unit: unitValue,
      rawInput: data.rawInput
    };

    invalidFields.value.delete(field);

    // Check temperature discrepancies
    checkTemperatureDiscrepancies();

    return true;
  }

  invalidFields.value.add(field);
  toast.add({
    severity: 'error',
    summary: 'Invalid Temperature',
    detail: `${field} must be a number followed by C or F`,
    life: 5000
  });
  return false;
}

function checkTemperatureDiscrepancies() {
  const { billedSetpoint, actualSetpoint, returnTemp } = unitCheckData.value;

  temperatureDiscrepancies.value.clear();

  if (billedSetpoint.value !== null && actualSetpoint.value !== null) {
    const withinThreshold = tempWithinThreshold(
        billedSetpoint.value,
        billedSetpoint.unit as 'C' | 'F',
        actualSetpoint.value,
        actualSetpoint.unit as 'C' | 'F',
        0.5,
        'C'
    );

    if (!withinThreshold) {
      temperatureDiscrepancies.value.add('setpoint');
      toast.add({
        severity: 'warn',
        summary: 'Setpoint Discrepancy!',
        detail: 'The actual setpoint is not within 0.5°C of the billed setpoint.',
        life: 5000
      });
    }
  }

  if (returnTemp.value !== null && actualSetpoint.value !== null) {
    const withinThreshold = tempWithinThreshold(
        returnTemp.value,
        returnTemp.unit as 'C' | 'F',
        actualSetpoint.value,
        actualSetpoint.unit as 'C' | 'F',
        2.5,
        'C'
    );

    if (!withinThreshold) {
      temperatureDiscrepancies.value.add('return-temp');
      toast.add({
        severity: 'warn',
        summary: 'Return Temp Out Of Range!',
        detail: 'The return temperature is not within 2.5°C of the setpoint.',
        life: 5000
      });
    }
  }
}

async function validateUnitNumber(): Promise<void> {
  if (!unitCheckData.value.unitNumber) {
    invalidFields.value.add('unitNumber');
    return;
  }

  try {
    const response = await backendCall.get(`units/lookup?unit_number=${unitCheckData.value.unitNumber}`);
    if (response.data.data.isValid) {
      invalidFields.value.delete('unitNumber');
      unitCheckData.value.unitType = response.data.data.dbResponse?.unit_type || response.data.data.cnApiResponse?.CarKind || null;
    } else {
      invalidFields.value.add('unitNumber');
      toast.add({
        severity: 'warn',
        summary: 'Possible Invalid Unit Number!',
        detail: 'The entered unit number may not be valid! Please verify.',
        life: 10000
      });
    }
  } catch (error) {
    console.error('Error validating unit number:', error);
    toast.add({
      severity: 'error',
      summary: 'Validation Error',
      detail: 'An error occurred while validating the unit number.',
      life: 3000
    });
    invalidFields.value.add('unitNumber');
  }
}

const isSubmitting = ref(false);

async function submitNewYardCheckUnit() {
  if (isSubmitting.value) return;

  isSubmitting.value = true;

  try {
    // Validate all fields
    const temperatureFields = ['billedSetpoint', 'actualSetpoint', 'returnTemp', 'supplyTemp'] as const;
    for (const field of temperatureFields) {
      if (!validateTemperatureField(field)) {
        isSubmitting.value = false;
        return;
      }
    }

    await validateUnitNumber();

    if (invalidFields.value.size > 0) {
      toast.add({
        severity: 'error',
        summary: 'Validation Error',
        detail: 'Please correct all invalid fields before submitting.',
        life: 5000
      });
      isSubmitting.value = false;
      return;
    }

    // Prepare data for submission
    const dataToSubmit = {
      ...unitCheckData.value,
      billedSetpoint: unitCheckData.value.billedSetpoint.value !== null ? {
        value: unitCheckData.value.billedSetpoint.value,
        unit: unitCheckData.value.billedSetpoint.unit
      } : undefined,
      actualSetpoint: unitCheckData.value.actualSetpoint.value !== null ? {
        value: unitCheckData.value.actualSetpoint.value,
        unit: unitCheckData.value.actualSetpoint.unit
      } : undefined,
      returnTemp: unitCheckData.value.returnTemp.value !== null ? {
        value: unitCheckData.value.returnTemp.value,
        unit: unitCheckData.value.returnTemp.unit
      } : undefined,
      supplyTemp: unitCheckData.value.supplyTemp.value !== null ? {
        value: unitCheckData.value.supplyTemp.value,
        unit: unitCheckData.value.supplyTemp.unit
      } : undefined
    };

    const submitYardCheckUnitCall = await backendCall.patch(`yard-check/${props.yardCheckId}/unit/${props.unitData.unit_check_id}`, dataToSubmit);
    if (!submitYardCheckUnitCall) {
      throw new Error('API Error');
    }

    emit("refresh-yardcheck");
    visible.value = false;

    toast.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Unit check updated successfully!',
      life: 3000
    });

  } catch (e) {
    console.error('Error submitting unit check:', e);
    toast.add({
      severity: 'error',
      summary: 'Could not submit unit check!',
      detail: 'An error occurred while submitting the unit check!',
      life: 5000
    });
  } finally {
    isSubmitting.value = false;
  }
}

</script>

<template>
  <Dialog v-model:visible="visible" appendTo="body" modal :breakpoints="{'960px': '75vw', '640px': '100vw'}"
          :style="{width: '30vw'}" :header="`Edit ${unitCheckData.unitNumber}`" :draggable="false" :resizable="false" :closable="true">
    <form class="flex flex-col gap-4 mt-4" @submit.prevent="submitNewYardCheckUnit">
      <!-- Unit Number -->
      <div class="w-full">
        <label for="unit-number" class="block mb-1 text-color text-base">Unit Number</label>
        <div class="flex flex-col gap-2">
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-hashtag mb-2"></i>
            </InputGroupAddon>
            <InputText name="unit-number" disabled autofocus v-model.trim="unitCheckData.unitNumber"
                       :class="{ 'p-invalid': invalidFields.has('unitNumber') }"
                       @blur="validateUnitNumber" type="text" class="w-full"
                       id="unit-number"/>
          </InputGroup>
          <InlineMessage v-if="unitCheckData.unitNumber && invalidFields.has('unitNumber')" severity="warn">
            Unit Number May Be Invalid! &nbsp; Please Verify.
          </InlineMessage>
        </div>
      </div>

      <!-- Event Code and Unit Type -->
      <div class="flex gap-4">
        <div class="w-full">
          <label for="event_code" class="block mb-1 text-color text-base">Event Code</label>
          <InputGroup>
            <InputGroupAddon>EV</InputGroupAddon>
            <Select name="event_code" v-model="unitCheckData.eventCode" class="w-full"
                    id="event-code" :options="eventCodeOptions"/>
          </InputGroup>
        </div>
        <div class="w-full">
          <label for="unit-type" class="block mb-1 text-color text-base">Unit Type</label>
          <Select name="unit_type" disabled v-model="unitCheckData.unitType" class="w-full"
                    id="unit-type" :options="unitTypeOptions"/>
        </div>
        <div class="w-full text-center">
          <label for="rvr_switch" class="block text-color text-base mb-4">On RVR</label>
          <InputSwitch v-model="unitCheckData.onRvr" id="rvr_switch"></InputSwitch>
          <!--          <Checkbox v-model="unitCheckData.onRvr" :binary="true" id="rvr_switch"></Checkbox>-->
        </div>
      </div>

      <!-- Temperature Fields -->
      <div class="flex gap-4">
        <div class="w-full">
          <label for="billed-setpoint" class="block mb-1 mt-2 text-color text-base">Billed Setpoint</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="fa-solid fa-temperature-three-quarters"></i>
            </InputGroupAddon>
            <InputText name="billed_setpoint" type="text" v-model="unitCheckData.billedSetpoint.rawInput" class="w-full"
                       id="billed-setpoint" :class="{ 'p-invalid': invalidFields.has('billedSetpoint') }"
                       @blur="validateTemperatureField('billedSetpoint')"/>
          </InputGroup>
          <InlineMessage v-if="temperatureDiscrepancies.has('setpoint')" severity="warn">Setpoint Discrepancy!</InlineMessage>
        </div>
        <div class="w-full">
          <label for="actual-setpoint" class="block mb-1 mt-2 text-color text-base">Actual Setpoint</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="fa-solid fa-temperature-three-quarters"></i>
            </InputGroupAddon>
            <InputText name="actual_setpoint" type="text" v-model="unitCheckData.actualSetpoint.rawInput" class="w-full"
                       id="actual-setpoint" :class="{ 'p-invalid': invalidFields.has('actualSetpoint') }"
                       @blur="validateTemperatureField('actualSetpoint')"/>
          </InputGroup>
          <InlineMessage v-if="temperatureDiscrepancies.has('setpoint')" severity="warn">Setpoint Discrepancy!</InlineMessage>
        </div>
      </div>
      <div class="flex gap-4">
        <div class="w-full">
          <label for="return-temp" class="block mb-1 mt-2 text-color text-base">Return Temp</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="fa-solid fa-temperature-three-quarters"></i>
            </InputGroupAddon>
            <InputText name="return_temp" type="text" v-model="unitCheckData.returnTemp.rawInput" class="w-full" id="return-temp"
                       :class="{ 'p-invalid': invalidFields.has('returnTemp') }"
                       @blur="validateTemperatureField('returnTemp')"/>
          </InputGroup>
          <InlineMessage v-if="temperatureDiscrepancies.has('return-temp')" severity="warn">Out Of Range!</InlineMessage>
        </div>
        <div class="w-full">
          <label for="supply-temp" class="block mb-1 mt-2 text-color text-base">Supply Temp</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="fa-solid fa-temperature-three-quarters"></i>
            </InputGroupAddon>
            <InputText name="supply_temp" type="text" v-model="unitCheckData.supplyTemp.rawInput" class="w-full" id="supply-temp"
                       :class="{ 'p-invalid': invalidFields.has('supplyTemp') }"
                       @blur="validateTemperatureField('supplyTemp')"/>
          </InputGroup>
        </div>
        <div class="w-full text-center">
          <label for="rvr_switch" class="block mt-2 text-color text-base mb-4">Defrost</label>
          <InputSwitch v-model="unitCheckData.inDefrost" id="rvr_switch"></InputSwitch>
          <!--          <Checkbox v-model="unitCheckData.onRvr" :binary="true" id="rvr_switch"></Checkbox>-->
        </div>
      </div>

      <!-- Fuel Fields -->
      <div class="flex gap-4">
        <div class="w-full">
          <label for="start-fuel" class="block mb-1 mt-2 text-color text-base">Fuel Level</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-gauge text-lg"></i>
            </InputGroupAddon>
            <Select name="start_fuel" v-model="unitCheckData.startFuelLevel" class="w-full"
                    id="start-fuel" :options="fuelOptions"/>
          </InputGroup>
        </div>
        <div class="w-full">
          <label for="fuel-added" class="block mb-1 mt-2 text-color text-base">Fuel Added</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-gauge text-lg"></i>
            </InputGroupAddon>
            <InputNumber v-model="unitCheckData.fuelAdded" id="fuel-added" title="Fuel Added"></InputNumber>
            <InputGroupAddon>L</InputGroupAddon>
          </InputGroup>
        </div>
      </div>

      <!-- End Fuel and Truck Number (conditionally rendered) -->
      <div v-if="unitCheckData.fuelAdded" class="flex justify-center gap-4">
        <div class="w-full">
          <label for="end-fuel" class="block mb-1 mt-2 text-color text-base">End Fuel</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-gauge text-lg"></i>
            </InputGroupAddon>
            <Select name="end-fuel" v-model="unitCheckData.endFuelLevel" class="w-full" id="end-fuel"
                    :options="fuelOptions.toReversed()"/>
          </InputGroup>
        </div>
        <div class="w-full">
          <label for="truck_number" class="block mb-1 mt-2 text-color text-base">Truck #</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-truck text-lg"></i>
            </InputGroupAddon>
            <InputNumber v-model="unitCheckData.truckNumber" id="truck_number" title="Truck #"></InputNumber>
          </InputGroup>
        </div>
      </div>

      <!-- Alarms -->
      <div>
        <label for="alarms" class="block mb-1 mt-2 text-color text-base">Alarms</label>
        <InputGroup>
          <InputGroupAddon>
            <i class="pi pi-exclamation-triangle mb-2"></i>
          </InputGroupAddon>
          <AutoComplete :multiple="true" v-model="unitCheckData.alarms" :suggestions="alarmSuggestions"
                        :option-label="(alarmSuggestions) => {return alarmSuggestions.manufacturer + ' - ' + alarmSuggestions.alarm_code + ' - ' + alarmSuggestions.alarm_name}"
                        :force-selection="false" @complete="searchAlarms">
            <template #option="slotProps">
              {{slotProps.option.manufacturer}} - {{ slotProps.option.alarm_code }} - {{ slotProps.option.alarm_name }}
            </template>
          </AutoComplete>
        </InputGroup>
      </div>

      <!-- Notes -->
      <div>
        <label for="notes" class="block mb-1 mt-2 text-color text-base">Notes</label>
        <InputGroup>
          <InputGroupAddon>
            <i class="pi pi-file mb-2"></i>
          </InputGroupAddon>
          <Textarea name="notes" v-model="unitCheckData.notes" class="w-full" id="notes"/>
        </InputGroup>
      </div>
    </form>

    <!-- Dialog Footer -->
    <template #footer>
      <div class="flex gap-4 justify-end border-t border-surface pt-8">
        <Button label="Cancel" @click="visible = false" class="p-button-text"></Button>
        <Button label="Submit" @click="submitNewYardCheckUnit" :disabled="isSubmitting || invalidFields.size > 0" class="p-button-rounded"></Button>
      </div>
    </template>
  </Dialog>
</template>

<style scoped>

</style>