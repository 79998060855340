<script setup>
import {ref} from 'vue';
import InputGroupAddon from "primevue/inputgroupaddon";
import InputGroup from "primevue/inputgroup";
import backendCall from "../../services/AxiosService";
import {useToast} from "primevue/usetoast";

const emit = defineEmits(['update-clips']);
const dismountClipDialogVisible = defineModel();
const toast = useToast();

const dismountClipFormObject = ref({
  clipNumber: null,
  container: null,
  fuelLevel: null,
  setpoint: null,
  return: null,
  supply: null,
  notes: null
});

const fuelOptions = ref(['E', '1/8', '1/4', '3/8', '5/16', '7/16', '1/2', '9/16', '5/8', '3/4', '13/16', '7/8', 'F']);
const fuelLevel = ref('');

const submitClipDismount = async () => {
  try {
    const clipDismountCall = await backendCall.post('clip-ons/dismount', dismountClipFormObject.value);
    if (!clipDismountCall) {
      throw new Error('Failed to Install Clip')
    }
    dismountClipDialogVisible.value = false;
    emit('update-clips');
    toast.add({
      severity: 'success',
      summary: 'Success!',
      detail: `${dismountClipFormObject.value.clipNumber} has been removed from ${dismountClipFormObject.value.container}`,
      life: 3000
    });
  } catch (e) {
    toast.add({
      severity: 'error',
      summary: 'Error!',
      detail: 'An error occurred while trying to un-mount the clip-on.',
      life: 5000
    });
  }
}

</script>

<template>
  <Dialog v-model:visible="dismountClipDialogVisible" appendTo="body" modal
          :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '32vw'}"
          :draggable="false" :resizable="false">
    <template #header>
      <h1 class="m-0 text-surface-900 dark:text-surface-0 font-semibold text-xl leading-normal">Unmount Clip</h1>
    </template>
    <form class="flex flex-col gap-4 mt-4">
      <div class=" flex gap-4">
        <div class="w-full">
          <label for="clip" class="block mb-1 text-color text-base">Clip Number</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-bolt"></i>
            </InputGroupAddon>
            <InputText v-model="dismountClipFormObject.clipNumber" name="clip" type="text" id="clip" placeholder="Clip #"/>
          </InputGroup>
        </div>
        <div class="w-full">
          <label for="container" class="block mb-1 text-color text-base">Container Number</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-hashtag"></i>
            </InputGroupAddon>
            <InputText v-model="dismountClipFormObject.container" name="container" type="text" id="container" placeholder="Container #"/>
          </InputGroup>
        </div>
      </div>
      <div class="flex gap-4">
        <div class="w-full">
          <label for="fuel-level" class="block mb-1 text-color text-base">Fuel Level</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-gauge text-lg"></i>
            </InputGroupAddon>
            <Dropdown v-model="dismountClipFormObject.fuelLevel" :options="fuelOptions.toReversed()"/>
          </InputGroup>
        </div>
        <div class="w-full">
          <label for="setpoint" class="block mb-1 text-color text-base">Setpoint</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="fa-solid fa-temperature-three-quarters"></i>
            </InputGroupAddon>
            <InputText v-model="dismountClipFormObject.setpoint" name="setpoint" type="text" class="w-full"
                       id="setpoint"
                       placeholder="Setpoint"/>
          </InputGroup>
        </div>
      </div>
      <div class="flex gap-4">
        <div class="w-full">
          <label for="return" class="block mb-1 text-color text-base">Return</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="fa-solid fa-temperature-three-quarters"></i>
            </InputGroupAddon>
            <InputText v-model="dismountClipFormObject.return" name="return" type="text" class="w-full" id="return"
                       placeholder="Return"/>
          </InputGroup>
        </div>
        <div class="w-full">
          <label for="supply" class="block mb-1 text-color text-base">Supply</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="fa-solid fa-temperature-three-quarters"></i>
            </InputGroupAddon>
            <InputText v-model="dismountClipFormObject.supply" name="supply" type="text" class="w-full" id="supply"
                       placeholder="Supply"/>
          </InputGroup>
        </div>
      </div>
      <div class="flex gap-4">
        <div class="w-full">
          <label for="notes" class="block mb-1 text-color text-base">Notes</label>
          <Textarea class="w-full" id="notes" name="notes" v-model="dismountClipFormObject.notes"></Textarea>
        </div>
      </div>
    </form>
    <template #footer>
      <div class="flex gap-4 justify-end border-t border-surface pt-8">
        <Button label="Cancel" @click="dismountClipDialogVisible = false" class="p-button-text"></Button>
        <Button label="Install" @click="submitClipDismount" class="p-button-rounded"></Button>
      </div>
    </template>
  </Dialog>

</template>

<style scoped>

</style>